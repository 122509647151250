import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { WorkbookService } from '../../_services';
import { Router } from '@angular/router';
import { PageScrollService, EasingLogic } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { SidebarPulloutComponent } from 'src/app/_shared/sidebar-pullout/sidebar-pullout.component';

@Component({
  selector: 'workbook-begin',
  templateUrl: './workbook-begin.component.pug',
  styleUrls: ['./workbook-begin.component.scss']
})
export class WorkbookBeginComponent implements OnInit {
  public typeListing = null;
  public showLoading: boolean = false;
  public currentType: string = '';
  public tags$: Observable<{id: number, name: string}[]>;
  @ViewChild('sideBar', {static: false}) pullOutList: SidebarPulloutComponent;

  constructor(private workbookSrv: WorkbookService,
              private router: Router,
              private scroller: PageScrollService,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.tags$ = this.workbookSrv.getTagListing();
  }

  public getListing(type: string) {
    this.typeListing = null;
    this.showLoading = true;

    this.workbookSrv.getExamListingByType(type).subscribe(
      response => {
        this.showLoading = false;
        this.currentType = type;

        if (204 !== response.status) {
          this.typeListing = response.body;

          this.scroller.scroll({
            document: this.document,
            scrollTarget: '#type-listing-box',
            duration: 700,
            easingLogic: this.quintEasing,
            scrollOffset: 75
          });
        } else {
          this.typeListing = [];
        }

      },
      err => {
        console.log(`[WorkbookComponent] ${err.message}`);
      }
    );
  }

  public viewTagListing(tagId: number) {
    this.pullOutList.show();
    this.pullOutList.tagId = tagId;
    this.pullOutList.pulloutType = 'tag-listing';
  }

  public viewPracticeSetMapping() {
    this.pullOutList.show();
    this.pullOutList.pulloutType = 'practice-set-mapping';
  }

  public gotoExam(id: number) {
    switch (this.currentType) {
      case 'mock-exam':
        this.router.navigate(['workbook', this.currentType, id, 'short-form']);
        break;
      case 'additional-topic':
        this.router.navigate(['workbook', this.currentType, id, 'at-introduction']);
        break;
    }
  }

  public quintEasing: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    t /= d/2;

    if (t < 1) return c/2*t*t*t*t*t + b;

    t -= 2;

    return c/2*(t*t*t*t*t + 2) + b;
  }
}
