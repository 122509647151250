import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getStates } from 'src/app/_helpers';
import { ShippingAddress } from 'src/app/_models';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'shipping',
  templateUrl: './shipping.component.pug',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {
  public shippingAddress: ShippingAddress;
  public shippingForm: FormGroup;
  public states: string[];
  public updatingShipping: boolean = false;
  public showApiErr: boolean = false;
  public showConfirm: boolean = true;

  public revealForm: boolean = false;
  public revealIntro: boolean = true;

  @Output() confirmEmit: EventEmitter<boolean> = new EventEmitter();

  constructor(private acctSrv: AccountService) { }

  ngOnInit() {
    this.states = getStates();
    this.acctSrv.getShippingAdress().subscribe(
      (res: ShippingAddress) => this.shippingAddress = res
    );
  }

  public getShippingForm() {
    this.revealIntro = false;
    this.shippingForm = new FormGroup({
      "address1":           new FormControl(this.shippingAddress.address1, Validators.required),
      "address2":           new FormControl(this.shippingAddress.address2),
      "city":               new FormControl(this.shippingAddress.city, Validators.required),
      "state":              new FormControl(this.shippingAddress.state, Validators.required),
      "zipCode":            new FormControl(this.shippingAddress.zipCode, [
        Validators.required,
        Validators.pattern('[0-9]{5}')
      ])
    });

    this.revealForm = true;
  }

  public onShippingSubmit() {
    this.updatingShipping = true;
    this.acctSrv.updateShippingAddress(this.shippingForm.value).pipe(
      catchError((err: HttpErrorResponse) => {
        if (400 === err.status) {
          this.showApiErr = true;
          this.updatingShipping = false;
        }

        console.error(err);
        return throwError(err);
      })
    )
    .subscribe(
      (res:ShippingAddress) => {
        if (res) {
          this.shippingAddress = res;
          this.resetShipping();
        }
      }
    );
  }

  public resetShipping() {
    this.updatingShipping = false;
    this.revealIntro = true;
    this.revealForm = false;
    this.shippingForm.reset();
  }

  public confirmShipping() {
    this.showConfirm = false;
    this.confirmEmit.emit(true);
  }

}
