import { HttpHeaders } from '@angular/common/http';

/**
 * Helper constant to keep track of local and remote env.
 * If host has port number (indexOf(':')), return local,
 * otherwise return student api url.
 */
export const API_DOMAIN = window.location.host.indexOf(':') !== -1
 ? '//' + window.location.hostname + ':8000/api/student'
 : '//api.' + (window.location.hostname + '/api/student').replace('student.', '');

 export const APP_DOMAIN = window.location.host.indexOf(':') !== -1
 ? '//' + window.location.hostname + ':4200'
 : '//' + window.location.hostname;

 /**
 * helper for building post http requests
 */
export function postRequestHelper(args: any) {
    const params: any = {};

    // form the request URL
    params.url = API_DOMAIN + args.path;

    // if a request body was supplied
    if (args.hasOwnProperty('body')) params.body = JSON.stringify(args.body);

    // Send request bodies as JSON
    params.headers = new HttpHeaders({'Content-Type': 'application/json'});

    return params;
  }

  /**
 * helper for building get http requests
 */
export function getRequestHelper(path: string) {
    const params: any = {};

    // form the request URL
    params.url = API_DOMAIN + path;

    return params;
  }
