import { Component, Input } from '@angular/core';
import { renderAvWindow } from '../../../_helpers';

@Component({
  selector: 'av-strip',
  templateUrl: './av-strip.component.pug',
  styleUrls: ['./av-strip.component.scss']
})
export class AvStripComponent {
  @Input() avDirectory: string;
  @Input() imageSrc: string;
  @Input() video: {videoId: number, title: string};

  public initAvWindow(type: string, src: any, directory: string = null) {
    renderAvWindow(type, src, directory);
  }
}
