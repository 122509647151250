import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { getRequestHelper } from '../_helpers';
import { InstructionManualEntries } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class InstructionManualService {

  constructor(private http: HttpClient) { }

  public getEntries(): Observable<InstructionManualEntries> {
    const { url } = getRequestHelper(`/manual/entries`);
    return this.http.get<InstructionManualEntries>(url);
  }

  public getEntry(route) {
    const { url } = getRequestHelper(`/manual/entries/${route}`);
    return this.http.get(url);
  }
}
