import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Slide } from 'src/app/_models';
import { OsceService } from 'src/app/_services';

@Component({
  selector: 'osce-slide-deck',
  templateUrl: './osce-slide-deck.component.pug',
  styleUrls: ['./osce-slide-deck.component.scss']
})
export class OsceSlideDeckComponent implements OnInit {
  public deckId: number|string;
  public slidesByDeck$: Observable<Slide[]>;

  constructor(private route: ActivatedRoute,
              private osceSrv: OsceService) { }

  ngOnInit() {
    let routeId = this.route.snapshot.paramMap.get('deckId');
    this.deckId = /^\d+$/.test(routeId) ? +routeId : routeId;
    this.slidesByDeck$ = this.osceSrv.getSlidesByDeckId(this.deckId);
  }

}
