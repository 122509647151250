import { Component, Input } from '@angular/core';

@Component({
  selector: 'hero',
  templateUrl: './hero-image.component.pug'
})
export class HeroImageComponent {
  @Input() heading: string;
  @Input() subHeading: string;
  @Input() pageTag: string = 'default';
}
