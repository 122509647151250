import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { getRequestHelper } from '../_helpers';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class FeedbackService {
    constructor(private http: HttpClient) { }

    public feedback: Subject<string> = new Subject<string>();

    public setFeedback(feedback: string) {
        this.feedback.next(feedback);
    }

    public getEcommExpirationMsg(): Observable<{message: string}> {
        const { url } = getRequestHelper(`/shop/expiration-message`);
        return this.http.get<{message: string}>(url);
    }

    public getExpiringSoonMsgByType(type: string): Observable<{message: string}> {
        const { url } = getRequestHelper(`/user/expiration-messages/${type}`);
        return this.http.get<{message: string}>(url);
    }
}
