import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { getToken } from 'src/app/_helpers';
import { PurchaseReceipt } from 'src/app/_models';
import { AuthService, EcommerceService, FeedbackService } from 'src/app/_services';

@Component({
  selector: 'receipt',
  templateUrl: './receipt.component.pug',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit, OnDestroy {
  public receipt: PurchaseReceipt;
  public showConfirmationError: boolean = false;
  public abaScreenshotMsg$: Observable<{message: string}>

  private paymentIntentId: string;
  private intervalId;
  private retryAttempts: number = 0;


  constructor(private eCommSrv: EcommerceService,
              private authSrv: AuthService,
              private feedbackSrv: FeedbackService,
              private jwtSrv: JwtHelperService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.paymentIntentId = this.route.snapshot.paramMap.get('paymentIntentId');
    this.intervalId = setInterval(this.retrieveReceipt.bind(this), 2000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private retrieveReceipt() {
    this.eCommSrv.confirmStripe(this.paymentIntentId).toPromise().then(
      res => {
        this.authSrv.reauthenticate().subscribe(
          res => {
            const token = this.jwtSrv.decodeToken(getToken());

            if (token.isUnverifiedAppliedBuyer) {
              this.abaScreenshotMsg$ = this.feedbackSrv.getEcommExpirationMsg();
            }
          }
        );
        clearInterval(this.intervalId);
        this.receipt = res;
      },
      rej => {
        if (this.retryAttempts > 29) {
          clearInterval(this.intervalId);
          this.showConfirmationError = true;
        } else {
          this.retryAttempts++;
        }
      }
    );
  }
}
