import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dtFormat' })
export class DateTimeFormatter implements PipeTransform {
    constructor() { }

    transform(datetime: any, format: string): any {
        if (datetime) {
            return moment(datetime).format(format);
        }
    }
}
