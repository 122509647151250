import { DOCUMENT } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { PageScrollService } from "ngx-page-scroll-core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { noSort } from "src/app/_helpers";
import { EcommerceItem } from "src/app/_models";
import { EcommerceService } from "src/app/_services";
import quintEasing from "../../_helpers/helpers-easing";
import { standaloneProducts } from "./standalone/standalone-products";

@Component({
  selector: "product",
  templateUrl: "./products.component.pug",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  public ecommItems$: Observable<EcommerceItem[]>;
  public productsLoadingError: boolean = false;
  public noSort = noSort;
  public standaloneProducts = standaloneProducts;

  constructor(
    private eCommSrv: EcommerceService,
    private scroller: PageScrollService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit() {
    this.ecommItems$ = this.eCommSrv.getEcommerceItems().pipe(
      catchError((err: HttpErrorResponse) => {
        this.productsLoadingError = true;
        return throwError(err);
      })
    );
  }

  public revealCart() {
    this.eCommSrv.utilSetCartUpdated(true);
  }

  public scrollToProducts(target: string, duration: number = 150) {
    setTimeout(() => {
      this.scroller.scroll({
        document: this.document,
        scrollTarget: target,
        duration: 1000,
        easingLogic: quintEasing,
      });
    }, duration);
  }
}
