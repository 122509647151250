import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";

import { SharedRouting } from "./shared.routing";

import {
  LowercaseHyphensPipe,
  SafeHtmlPipe,
  DateTimeFormatter,
  SplitPipe,
} from "../_pipes";

import { DocumentComponent } from "./document/document.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HeroImageComponent } from "./hero-image/hero-image.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { SpinnerSmallComponent } from "./spinner/spinner-small/spinner-small.component";
import { SpinnerTinyComponent } from "./spinner/spinner-tiny/spinner-tiny.component";
import { UserFeedbackComponent } from "./user-feedback/user-feedback.component";
import { VideoPlaceholderColumnComponent } from "./placeholders/video-placeholder-column/video-placeholder-column.component";
import { SidebarPulloutComponent } from "./sidebar-pullout/sidebar-pullout.component";
import { SidebarPulloutPrimerListingComponent } from "./sidebar-pullout/sidebar-pullout-primer-listing/sidebar-pullout-primer-listing.component";
import { SidebarPulloutTaggedQuestionsComponent } from "./sidebar-pullout/sidebar-pullout-tagged-questions/sidebar-pullout-tagged-questions.component";
import { AvStripComponent } from "./placeholders/av-strip/av-strip.component";
import { AvComponent } from "./av/av.component";
import { PlaybackRateComponent } from "./av/playback-rate/playback-rate.component";
import { SidebarPracticeSetMappingComponent } from "./sidebar-pullout/sidebar-practice-set-mapping/sidebar-practice-set-mapping.component";
import { SlideDeckComponent } from "./slide-deck/slide-deck.component";

@NgModule({
  declarations: [
    DocumentComponent,
    FooterComponent,
    HeaderComponent,
    HeroImageComponent,
    SpinnerComponent,
    SpinnerSmallComponent,
    UserFeedbackComponent,
    LowercaseHyphensPipe,
    SafeHtmlPipe,
    SplitPipe,
    DateTimeFormatter,
    VideoPlaceholderColumnComponent,
    SidebarPulloutComponent,
    SidebarPulloutPrimerListingComponent,
    SidebarPulloutTaggedQuestionsComponent,
    AvStripComponent,
    AvComponent,
    PlaybackRateComponent,
    SidebarPracticeSetMappingComponent,
    SpinnerTinyComponent,
    SlideDeckComponent,
  ],
  imports: [CommonModule, SharedRouting, NgbAccordionModule],
  exports: [
    DocumentComponent,
    FooterComponent,
    HeaderComponent,
    HeroImageComponent,
    SpinnerComponent,
    SpinnerSmallComponent,
    SpinnerTinyComponent,
    UserFeedbackComponent,
    LowercaseHyphensPipe,
    SafeHtmlPipe,
    SplitPipe,
    DateTimeFormatter,
    VideoPlaceholderColumnComponent,
    SidebarPulloutComponent,
    AvStripComponent,
    AvComponent,
    SlideDeckComponent,
  ],
})
export class SharedModule {}
