import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { PageScrollService, EasingLogic } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { FeedbackService, OsceService, VideoService } from '../../_services';
import { Subscription } from 'rxjs';
import { OsceTopic } from '../../_models';
import { renderAvWindow } from '../../_helpers';
import quintEasing from "../../_helpers/helpers-easing";

@Component({
  selector: 'osce-topic',
  templateUrl: './osce-topic.component.pug',
  styleUrls: ['./osce-topic.component.scss']
})
export class OsceTopicComponent implements OnInit, OnDestroy {
  private feedbackSubr: Subscription;
  public feedback: string;
  public topic: OsceTopic;
  public hasAv: boolean = true;

  constructor(private route: ActivatedRoute,
              private feedbackSrv: FeedbackService,
              private osceSrv: OsceService,
              private scroller: PageScrollService,
              @Inject(DOCUMENT) private document: any) {
    this.feedback = 'none';
    this.feedbackSubr = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
  }

  ngOnInit() {
    this.feedback = 'loading';

    this.route.params.subscribe(
      (id: Params) => {
        this.osceSrv.getTopicById(id['topicId']).subscribe(
          (res: OsceTopic) => {
            this.topic = res;
            this.hasAv = (null !== this.topic.video || null !== this.topic.images) ? true : false;

            this.scroller.scroll({
              document: this.document,
              scrollTarget: '#topicBox',
              duration: 1200,
              easingLogic: quintEasing
            });
          }
        );
      }
    );
  }

  public gotoTop() {
    this.scroller.scroll({
      document: this.document,
      scrollTarget: '#topics',
      duration: 700,
      easingLogic: quintEasing
    });
  }

  public initAvWindow(type: string, src: any, directory: string = null) {
    renderAvWindow(type, src, directory);
  }

  ngOnDestroy() {
    this.feedbackSubr.unsubscribe();
  }

}
