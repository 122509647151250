import { Component, OnInit, Input } from '@angular/core';
import { WorkbookService } from '../../_services';
import { Observable } from 'rxjs';

@Component({
  selector: 'workbook-part-message',
  templateUrl: './workbook-part-message.component.pug',
  styleUrls: ['./workbook-part-message.component.scss']
})
export class WorkbookPartMessageComponent implements OnInit {
  @Input() examType: string;
  @Input() examPart: string;
  public message$: Observable<{message: string}>;

  constructor(private workbookSrv: WorkbookService) { }

  ngOnInit() {
    this.message$ = this.workbookSrv.getExamPartMessage(this.examType, this.examPart);
  }

}
