import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EcommerceRouting } from './ecommerce.routing';
import { SharedModule } from '../_shared/shared.module';
import { ReceiptComponent } from './receipt/receipt.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { StripeComponent } from './stripe/stripe.component';
import { ShippingComponent } from './shipping/shipping.component';
import { ProductsComponent } from './products/products.component';
import { ProductComponent } from './products/product.component';
import { QuantityPickerComponent } from './products/widgets/quantity-picker/quantity-picker.component';
import { SubscriptionIntervalPickerComponent } from './products/widgets/subscription-interval-picker/subscription-interval-picker.component';

@NgModule({
  declarations: [
    ReceiptComponent,
    CartComponent,
    CheckoutComponent,
    StripeComponent,
    ShippingComponent,
    ProductsComponent,
    ProductComponent,
    QuantityPickerComponent,
    SubscriptionIntervalPickerComponent
  ],
  imports: [
    CommonModule,
    EcommerceRouting,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule
  ]
})
export class EcommerceModule { }
