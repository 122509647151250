export const standaloneProducts = [
  {
    type: "applied",
    isStandalone: true,
    popupTitle: "OSCE Small Groups",
    popupMessage:
      "OSCE Small Groups can be joined by purchasing Private Online Exams.",
    product: {
      title: "OSCE Small Group",
      details: "Access to an OSCE Small group",
      canPurchaseMultiple: false,
      price: "210.00",
      uuid: "prd_private_online_exam",
      hasSubscriptionOptions: false,
      subscriptionIntervals: null,
      questionCount: null,
    },
  },
];
