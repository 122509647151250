import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService, FeedbackService } from '../_services';
import { JwtHelperService } from '@auth0/angular-jwt';


@Component({
  selector: 'login',
  templateUrl: './login.component.pug',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submitted: boolean;
  public showError: boolean;
  public showExpError: boolean;
  public showAbaExpError: boolean;
  public showBlockedError: boolean;
  public showLoginForm: boolean = false;
  public processing: boolean;

  public abaLoginFailureMsg$: Observable<{message: string}>;

  private redirectUrl;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private feedbackSrv: FeedbackService,
              private jwtSrv: JwtHelperService,
              private auth: AuthService) {

    if (this.auth.tokenIsCurrent()) {
      this.router.navigate(['/']);
    } else {
      this.submitted        = false;
      this.showError        = false;
      this.showExpError     = false;
      this.showAbaExpError  = false;
      this.showBlockedError = false;
      this.processing       = false;
      if (this.route.snapshot.queryParams.applied === 'true') {
         this.showLoginForm = true;
      }
    }
  }

  ngOnInit() {
    // clear all tokens
    localStorage.clear();

    /** was there a redirect URL? */
    let routeParams = this.route.snapshot.queryParams;
    if (routeParams['redirect']) {
      this.redirectUrl = routeParams['redirect'];
    }

    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  public revealLoginForm() { this.showLoginForm = true; }

  public onSubmit() {
    this.processing       = true;
    this.submitted        = true;

    this.showError        = false;
    this.showExpError     = false;
    this.showAbaExpError  = false;
    this.showBlockedError = false;

    this.auth.login(this.loginForm.value)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.processing = false;

          if (401 === err.status) {
            this.showError = true;
          } else if (403 === err.status) {
            this.showBlockedError = true;
          } else if (498 === err.status) {
            this.showAbaExpError = true;
            this.abaLoginFailureMsg$ = this.feedbackSrv.getExpiringSoonMsgByType('login_aba');
          } else if (499 === err.status) {
            this.showExpError = true;
          }

          return throwError(err);
        })
      )
      .subscribe(
        (res) => {
          if (this.auth.canNavigate(res.token)) {
            // set storage and navigate to dashboard
            this.auth.setStorage(res.token, res.refresh_token);

            // decode JWT
            const decodedToken = this.jwtSrv.decodeToken(res.token);

            if (decodedToken.expiringSoon) {
              if ('aba' === decodedToken.expirationType) {
                this.router.navigate(['account', 'expire', 'aba']);
              } else {
                this.router.navigate(['account', 'expire', 'normal']);
              }
            } else if (this.redirectUrl) {
              this.router.navigateByUrl(this.redirectUrl)
                .catch(() => this.router.navigate(['/']));
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.showBlockedError = true;
            this.processing = false;
          }
        }
    );
  }
}
