export const practiceSetConversions = {
    "1": {
        "MOCK_EXAMS": {
            "1": {
                "id": 42,
                "name": "Trauma & Head Injury",
                "video_course": "C"
            },
            "2": {
                "id": 30,
                "name": "Obesity & Obstructive Sleep Apnea",
                "video_course": "B"
            },
            "3": {
                "id": 36,
                "name": "Preeclampsia & Eclampsia",
                "video_course": "C"
            },
            "4": {
                "id": 48,
                "name": "Anterior Mediastinal Mass",
                "video_course": null
            },
            "5": {
                "id": 5,
                "name": "Spinal Surgery",
                "video_course": "A"
            },
            "6": {
                "id": 24,
                "name": "Carotid Endarterectomy",
                "video_course": "B"
            },
            "7": {
                "id": 11,
                "name": "Tracheoesophageal Fistula",
                "video_course": "A"
            },
            "8": {
                "id": 18,
                "name": "Chronic Renal Failure",
                "video_course": "B"
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 48,
                "name": "Anesthesia Machine",
                "video_course": null
            },
            "2": {
                "id": 41,
                "name": "Sickle Cell Disease",
                "video_course": "C"
            },
            "3": {
                "id": 18,
                "name": "Masseter Muscle Spasm",
                "video_course": "B"
            },
            "4": {
                "id": 24,
                "name": "Thyrotoxicosis",
                "video_course": "B"
            },
            "5": {
                "id": 6,
                "name": "Esophagectomy / Aspiration",
                "video_course": "A"
            },
            "6": {
                "id": 36,
                "name": "Cirrhosis",
                "video_course": "C"
            },
            "7": {
                "id": 12,
                "name": "Post-Dural Puncture Headache",
                "video_course": "A"
            },
            "8": {
                "id": 30,
                "name": "Local Anesthetic Toxicity",
                "video_course": "B"
            }
        }
    },
    "2": {
        "MOCK_EXAMS": {
            "1": {
                "id": 29,
                "name": "Obstetrics & Massive Blood Transfusion",
                "video_course": "B"
            },
            "2": {
                "id": 2,
                "name": "Scoliosis Repair",
                "video_course": "A"
            },
            "3": {
                "id": 35,
                "name": "Shoulder Surgery in the Seated Position",
                "video_course": "C"
            },
            "4": {
                "id": 17,
                "name": "Pheochromocytoma",
                "video_course": "B"
            },
            "5": {
                "id": 10,
                "name": "Cerebral Aneurysm",
                "video_course": "A"
            },
            "6": {
                "id": 23,
                "name": "Cystic Fibrosis",
                "video_course": "B"
            },
            "7": {
                "id": 41,
                "name": "Transurethral Resection of the Prostate (TURP)",
                "video_course": "C"
            },
            "8": {
                "id": 47,
                "name": "Coronary Artery Bypass Graft (CABG)",
                "video_course": null
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 38,
                "name": "Liposuction",
                "video_course": "C"
            },
            "2": {
                "id": 45,
                "name": "Cancer Pain",
                "video_course": null
            },
            "3": {
                "id": 5,
                "name": "Complex Regional Pain Syndrome",
                "video_course": "A"
            },
            "4": {
                "id": 23,
                "name": "Epiglottitis",
                "video_course": "B"
            },
            "5": {
                "id": 11,
                "name": "Pituitary Tumor",
                "video_course": "A"
            },
            "6": {
                "id": 34,
                "name": "Pulmonary Edema",
                "video_course": "C"
            },
            "7": {
                "id": 29,
                "name": "Stellate Ganglion Block",
                "video_course": "B"
            },
            "8": {
                "id": 17,
                "name": "Denervated Heart / Pregnant Patient",
                "video_course": "B"
            }
        }
    },
    "3": {
        "MOCK_EXAMS": {
            "1": {
                "id": 34,
                "name": "Obstetrics & Aortic Stenosis",
                "video_course": "C"
            },
            "2": {
                "id": 4,
                "name": "Cervical Spinal Cord Injury",
                "video_course": "A"
            },
            "3": {
                "id": 16,
                "name": "Ligation of PDA",
                "video_course": "A"
            },
            "4": {
                "id": 22,
                "name": "Mediastinoscopy",
                "video_course": "B"
            },
            "5": {
                "id": 28,
                "name": "Transphenoidal Hypophysectomy",
                "video_course": "B"
            },
            "6": {
                "id": 9,
                "name": "Thoracic Aortic Dissection",
                "video_course": "A"
            },
            "7": {
                "id": 40,
                "name": "Thryoidectomy",
                "video_course": "C"
            },
            "8": {
                "id": 45,
                "name": "CRF & Kidney Transplant",
                "video_course": null
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 28,
                "name": "Rheumatoid Arthritis",
                "video_course": "B"
            },
            "2": {
                "id": 4,
                "name": "Esophagectomy / Blood Transfusion",
                "video_course": "A"
            },
            "3": {
                "id": 16,
                "name": "Porphyria",
                "video_course": "A"
            },
            "4": {
                "id": 43,
                "name": "Practice Management - Endoscopy Suite",
                "video_course": null
            },
            "5": {
                "id": 33,
                "name": "Physician Substance Abuse",
                "video_course": "C"
            },
            "6": {
                "id": 46,
                "name": "Modes of Ventilation",
                "video_course": null
            },
            "7": {
                "id": 10,
                "name": "Laryngeal Polyps",
                "video_course": "A"
            },
            "8": {
                "id": 22,
                "name": "Neonatal Resuscitation",
                "video_course": "B"
            }
        }
    },
    "4": {
        "MOCK_EXAMS": {
            "1": {
                "id": 21,
                "name": "Obstetrics & Sickle Cell Disease",
                "video_course": "B"
            },
            "2": {
                "id": 8,
                "name": "Burn & Trauma",
                "video_course": "A"
            },
            "3": {
                "id": 12,
                "name": "Congenital Diaphragmantic Hernia",
                "video_course": "A"
            },
            "4": {
                "id": 27,
                "name": "Obstetrics & Ischemic Cardiomyopathy",
                "video_course": "B"
            },
            "5": {
                "id": 39,
                "name": "CABG / Mitral Valve Replacement",
                "video_course": "C"
            },
            "6": {
                "id": 33,
                "name": "Pyloric Stenosis",
                "video_course": "C"
            },
            "7": {
                "id": 15,
                "name": "Obstructive Sleep Apnea &  Uvulopalatopharyngoplasty (OSA & UPPP)",
                "video_course": "A"
            },
            "8": {
                "id": 44,
                "name": "Cardiac Ablation",
                "video_course": "C"
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 44,
                "name": "Status Asthmaticus",
                "video_course": null
            },
            "2": {
                "id": 21,
                "name": "Mitral Valve Prolapse",
                "video_course": "B"
            },
            "3": {
                "id": 3,
                "name": "Anaphylaxis",
                "video_course": "A"
            },
            "4": {
                "id": 35,
                "name": "Axillary Block & Preoperative Hypertension",
                "video_course": "C"
            },
            "5": {
                "id": 9,
                "name": "Coronary Artery Bypass Graft",
                "video_course": "A"
            },
            "6": {
                "id": 27,
                "name": "Tracheoesophageal Fistula",
                "video_course": "B"
            },
            "7": {
                "id": 15,
                "name": "Fetal Heart Rate Monitoring",
                "video_course": "A"
            },
            "8": {
                "id": 37,
                "name": "Lithotripsy",
                "video_course": "C"
            }
        }
    },
    "5": {
        "MOCK_EXAMS": {
            "1": {
                "id": 38,
                "name": "Open Globe Injury & CIED",
                "video_course": "C"
            },
            "2": {
                "id": 1,
                "name": "Trauma & the Pregnant Patient / Omphalocele",
                "video_course": "A"
            },
            "3": {
                "id": 32,
                "name": "Carcinoid Syndrome",
                "video_course": "B"
            },
            "4": {
                "id": 7,
                "name": "Tonsillectomy",
                "video_course": "A"
            },
            "5": {
                "id": 14,
                "name": "Pneumonectomy",
                "video_course": "A"
            },
            "6": {
                "id": 20,
                "name": "Laryngeal Papillomatosis",
                "video_course": "B"
            },
            "7": {
                "id": 46,
                "name": "Liver Transplant",
                "video_course": null
            },
            "8": {
                "id": 26,
                "name": "Endovascular Repair Aortic Aneurysm",
                "video_course": "B"
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 32,
                "name": "Tetralogy of Fallot",
                "video_course": "B"
            },
            "2": {
                "id": 2,
                "name": "Recent Stent Placement",
                "video_course": "A"
            },
            "3": {
                "id": 26,
                "name": "Total Parenteral Nutrition",
                "video_course": "B"
            },
            "4": {
                "id": 14,
                "name": "Autonomic Hyper-Reflexia",
                "video_course": "A"
            },
            "5": {
                "id": 8,
                "name": "Myasthenia Gravis",
                "video_course": "A"
            },
            "6": {
                "id": 40,
                "name": "Retrobulbar Block / Herpes Zoster",
                "video_course": "C"
            },
            "7": {
                "id": 20,
                "name": "Foreign Body Aspiration",
                "video_course": "B"
            },
            "8": {
                "id": 42,
                "name": "Hypertrophic Obstructive Cardiomyopathy (HOCM)",
                "video_course": "C"
            }
        }
    },
    "6": {
        "MOCK_EXAMS": {
            "1": {
                "id": 43,
                "name": "Obstetrics & Difficult Airway",
                "video_course": "C"
            },
            "2": {
                "id": 31,
                "name": "Pediatric Trauma",
                "video_course": "B"
            },
            "3": {
                "id": 3,
                "name": "Strabismus Surgery",
                "video_course": "A"
            },
            "4": {
                "id": 13,
                "name": "Pregnancy & Laparoscopy /  ECT",
                "video_course": "A"
            },
            "5": {
                "id": 6,
                "name": "Submandibular Abscess",
                "video_course": "A"
            },
            "6": {
                "id": 37,
                "name": "Femoropopliteal Bypass",
                "video_course": "C"
            },
            "7": {
                "id": 19,
                "name": "Cerebellar Tumor",
                "video_course": "B"
            },
            "8": {
                "id": 25,
                "name": "Arteriovenous Fistula (AVF)",
                "video_course": "B"
            }
        },
        "ADDITIONAL_TOPICS": {
            "1": {
                "id": 39,
                "name": "Clinical Consent",
                "video_course": null
            },
            "2": {
                "id": 13,
                "name": "Systemic Lupus Erythematosus",
                "video_course": "A"
            },
            "3": {
                "id": 25,
                "name": "MRI",
                "video_course": "B"
            },
            "4": {
                "id": 1,
                "name": "Denervated Heart",
                "video_course": "A"
            },
            "5": {
                "id": 19,
                "name": "Laryngectomy",
                "video_course": "B"
            },
            "6": {
                "id": 47,
                "name": "PALS - CPR of the Pediatric Patient",
                "video_course": null
            },
            "7": {
                "id": 7,
                "name": "Arachnoiditis / Vancomycin Reaction",
                "video_course": "A"
            },
            "8": {
                "id": 31,
                "name": "Interscalene Block & Tourniquet Use",
                "video_course": "B"
            }
        }
    }
}
