import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../_shared/shared.module';
import { WorkbookRouting } from './workbook.routing';

import { WorkbookComponent } from './workbook.component';
import { WorkbookShortFormComponent } from './workbook-short-form/workbook-short-form.component';
import { WorkbookTocComponent } from './workbook-toc/workbook-toc.component';
import { WorkbookBeginComponent } from './workbook-begin/workbook-begin.component';
import { WorkbookLongFormComponent } from './workbook-long-form/workbook-long-form.component';
import { WorkbookKeyTopicComponent } from './workbook-key-topic/workbook-key-topic.component';
import { WorkbookOperativeManagementComponent } from './workbook-operative-management/workbook-operative-management.component';
import { WorkbookAdditionalTopicIntroductionComponent } from './workbook-additional-topic-introduction/workbook-additional-topic-introduction.component';
import { WorkbookCaseScenarioComponent } from './workbook-case-scenario/workbook-case-scenario.component';
import { WorkbookExamSubpartComponent } from './workbook-exam-subpart/workbook-exam-subpart.component';
import { WorkbookPartMessageComponent } from './workbook-part-message/workbook-part-message.component';

@NgModule({
  declarations: [
    WorkbookComponent,
    WorkbookShortFormComponent,
    WorkbookTocComponent,
    WorkbookBeginComponent,
    WorkbookLongFormComponent,
    WorkbookKeyTopicComponent,
    WorkbookOperativeManagementComponent,
    WorkbookAdditionalTopicIntroductionComponent,
    WorkbookCaseScenarioComponent,
    WorkbookExamSubpartComponent,
    WorkbookPartMessageComponent,
  ],
  imports: [
    CommonModule,
    WorkbookRouting,
    SharedModule,
  ]
})
export class WorkbookModule { }
