import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'playback-rate',
  templateUrl: './playback-rate.component.pug',
  styleUrls: ['./playback-rate.component.scss']
})
export class PlaybackRateComponent {
  @Output() changeSpeed: EventEmitter<number> = new EventEmitter();

  manageRate(amount) {
    this.changeSpeed.emit(amount);
  }
}
