import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { InstructionManualEntries } from '../_models';
import { InstructionManualService } from '../_services/instruction-manual.service';

@Component({
  selector: 'manual',
  templateUrl: './manual.component.pug',
  styleUrls: ['./manual.component.scss']
})
export class ManualComponent implements OnInit {
  public entries$: Observable<InstructionManualEntries>;
  public isCollapsed = false;

  constructor(private manualSrv: InstructionManualService) { }

  ngOnInit() {
    this.entries$ = this.manualSrv.getEntries();
  }

  public noSort = (a, b) => a.key;

}
