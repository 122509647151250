import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorkbookService, FeedbackService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MockExamKeyTopic } from '../../_models';

@Component({
  selector: 'workbook-key-topic',
  templateUrl: './workbook-key-topic.component.pug',
  styleUrls: ['./workbook-key-topic.component.scss']
})
export class WorkbookKeyTopicComponent implements OnInit, OnDestroy {
  public keyTopics: MockExamKeyTopic[];
  public feedbackSubscription: Subscription;
  public feedback: string;
  public examType: string;

  constructor(private workbookSrv: WorkbookService,
              private route: ActivatedRoute,
              private feedbackSrv: FeedbackService) {
    this.feedbackSubscription = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
    this.feedback = 'none';
  }

  ngOnInit() {
    this.feedback = 'loading';
    const examId = this.route.snapshot.paramMap.get('id');
    this.examType = this.route.snapshot.paramMap.get('type');

    this.workbookSrv.getExamKeyTopics(+examId, this.examType).subscribe(
      res => {
        if (null !== res) {
          this.keyTopics = res;
        } else {
          this.keyTopics = [];
        }
      }
    );
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
