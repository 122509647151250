import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'quantity-picker',
  templateUrl: './quantity-picker.component.pug',
  styleUrls: ['./quantity-picker.component.scss']
})
export class QuantityPickerComponent {
  @Input() quantity: number;
  @Output() quantityChanged: EventEmitter<string> = new EventEmitter();
  public changeQuantity(action: string) { this.quantityChanged.emit(action)}
}
