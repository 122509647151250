import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { getRequestHelper } from '../_helpers';
import { OnlineDocument } from '../_models';


@Injectable({providedIn: 'root'})
export class DocumentService {
  constructor(private http: HttpClient) { }

  /**
   * Get all documents by category search ID
   */
  public getByCategory(searchId: string): Observable<OnlineDocument[]> {
      const { url } = getRequestHelper(`/documents/category/${searchId}`);
      return this.http.get<{id: number, title: string}[]>(url);
  }

  /**
   * Get full docmuent by ID
   */
  public getFullDocument(id: number|string): Observable<OnlineDocument> {
      const { url } = getRequestHelper(`/documents/full/${id}`);
      return this.http.get<OnlineDocument>(url);
  }
}
