import { Component, OnInit } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { SmallGroup } from "src/app/_models";
import { SmallGroupService } from "src/app/_services";
import { months } from "../../_helpers";
import { noSort } from "src/app/_helpers";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Report } from "notiflix/build/notiflix-report-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Router } from "@angular/router";

@Component({
  selector: "small-group",
  templateUrl: "./small-group.component.pug",
  styleUrls: ["./small-group.component.scss"],
})
export class SmallGroupComponent implements OnInit {
  public noSort: any = noSort;
  public smallGroups$: Observable<SmallGroup[]>;
  public error204: boolean = false;

  private readonly confirmUseTokenColor = "#4392fb";

  constructor(
    private smallGroupSrv: SmallGroupService,
    private router: Router
  ) {}

  ngOnInit() {
    this.smallGroups$ = this.smallGroupSrv.getSmallGroups().pipe(
      map((groups) => {
        if (null === groups) this.error204 = true;
        return groups;
      })
    );
  }

  public sortGroups(groups: SmallGroup[]) {
    const sorted = {};

    groups.forEach((g) => {
      const [year, month, day] = g.dateStr.split("-");

      // create a year and month properties on object
      if (!sorted.hasOwnProperty(`${year}-${month}`))
        sorted[`${year}-${month}`] = [];

      sorted[`${year}-${month}`].push({
        id: g.id,
        day,
        timeStart: `${g.dateStr} ${g.timeStart}`,
        timeEnd: `${g.dateStr} ${g.timeEnd}`,
        leader: g.leader,
        avatar: g.leaderAvatar,
        open: g.open,
      });
    });
    return sorted;
  }

  public getMonthString(monthStr: string) {
    return months[+monthStr - 1];
  }

  public createReservation(groupId: number) {
    Notify.init({ closeButton: true });
    Confirm.init({
      titleColor: this.confirmUseTokenColor,
      okButtonBackground:  this.confirmUseTokenColor,
      titleFontSize: "21px",
      messageMaxLength: 200
    });

    // Warn them that reservations use exam token
    Confirm.show("Create Reservation?", "Reserving a small group will use a Live Session Token. Tokens can be purchased on the products page if you do not possess one.", "ok", "cancel", () => {
      this.smallGroupSrv
        .createReservation(groupId)
        .pipe(
          map((resp) => {
            if (null === resp) {
              Report.success(
                "Reserved",
                "You successfully reserved a small group spot.",
                "ok",
                () => {
                  this.router.navigateByUrl("/account/edit");
                }
              );
            }
            return resp;
          }),
          catchError((err: HttpErrorResponse) => {
            if (497 === err.status) {
              Notify.info(
                "You do not possess a Live Session Token. Tokens can be purchased on the products page.",
                {
                  position: "center-center",
                  timeout: 3000,
                  width: "480",
                  backOverlay: true,
                }
              );
            } else {
              Notify.failure("System error. Contact customer support.", {
                position: "center-center",
                timeout: 3000,
                width: "480",
                backOverlay: true,
              });
            }
            return throwError(err);
          })
        )
        .subscribe();
    });
  }
}
