import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { WorkbookService, FeedbackService, VideoService} from '../../_services';
import { Observable, Subscription } from 'rxjs';
import { MockExamShortForm } from '../../_models';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'workbook-short-form',
  templateUrl: './workbook-short-form.component.pug',
  styleUrls: ['./workbook-short-form.component.scss']
})
export class WorkbookShortFormComponent implements OnInit, OnDestroy {
  public examId: number;
  public shortForm$: Observable<MockExamShortForm>;
  public feedbackSubscription: Subscription;
  public feedback: string;

  constructor(private workbookSrv: WorkbookService,
              private route: ActivatedRoute,
              private feedbackSrv: FeedbackService,
              private videoSrv: VideoService,
              @Inject(DOCUMENT) private document: any) {
    this.feedbackSubscription = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
    this.feedback = 'none';
  }

  ngOnInit() {
    this.feedback = 'loading';
    this.examId = this.route.snapshot.params['id'];
    this.shortForm$ = this.workbookSrv.getMockExamShortForm(this.examId);
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
