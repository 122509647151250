import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { Slide } from "../_models";
import { ActivatedRoute, Params } from "@angular/router";
import { SlideService } from "../_services";

@Component({
  selector: "media-slide-deck",
  templateUrl: "./media-slide-deck.component.pug",
  styleUrls: ["./media-slide-deck.component.scss"],
})
export class MediaSlideDeckComponent implements OnInit {
  public deckId;
  public slidesByDeck$: Observable<Slide[]>;

  constructor(private route: ActivatedRoute, private slideSrv: SlideService) {}

  ngOnInit() {
    this.route.params.subscribe(
      (param: Params) => {
        this.deckId = /^\d+$/.test(param['deckId']) ? +param['deckId'] : param['deckId'];
        this.slidesByDeck$ = this.slideSrv.getSlidesByDeckIdentifier(this.deckId);
      }
    );
  }
}
