import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { getRequestHelper } from '../_helpers';
import { FeedbackService } from './feedback.service';
import { OnlineCourseGroupListing, SignedCookies } from '../_models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  constructor(private http: HttpClient,
              private feedbackSrv: FeedbackService,
              private router: Router,
              private authSrv: AuthService) { }

  /**
   * get listing of video titles and IDs.
   */
  public getListingByCategory(category: string): Observable<{videoId: number, title: string}[]> {
    const { url } = getRequestHelper(`/videos/listing/categories/${category}`);
    return this.http.get<{videoId: number, title: string}[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * get video by tag name
   */
  public getByTag(tag: string): Observable<{videoId: number, title: string}> {
    const { url } = getRequestHelper(`/videos/tags/${tag}`);
    return this.http.get<{videoId: number, title: string}>(url).pipe(
      catchError(this.handleError)
    );
  }

  public getOnlineCourseGroups(): Observable<{id: number; name: string}[]> {
    const { url } = getRequestHelper(`/videos/category/online-course/groups`);
    return this.http.get<{id: number; name: string}[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  public getOnlineCourseVideosByGroup(groupId):Observable<OnlineCourseGroupListing> {
    const { url } = getRequestHelper(`/videos/group/${groupId}/videos`);
    return this.http.get<OnlineCourseGroupListing>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Return signed cookies so that viewer has access to view asset
   * @param videoId number
   * @param type image|video|audio string
   * @param directory string
   */
  public getSignedCookies(videoId: number, type: string, directory: string): Observable<SignedCookies> {
    const { url } = getRequestHelper(`/videos/${videoId}/${type}/${directory}`);
    return this.http.get<SignedCookies>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * helper error handler
   */
  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      this.router.navigateByUrl('/products');
    } else if (error.status === 401) {
      this.authSrv.logout();
      this.router.navigateByUrl('/login');
    } else {
      this.feedbackSrv.setFeedback('error');
    }

    console.log('[UBP Error] ' + error.message);
    return throwError(error);
  }
}
