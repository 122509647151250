import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards';
import { CheckoutComponent } from './checkout/checkout.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { ProductsComponent } from './products/products.component';

const ROUTES_ECOMM: Routes = [
    {
        path: 'products',
        component: ProductsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'purchase/:paymentIntentId/receipt',
        component: ReceiptComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES_ECOMM)],
    exports: [RouterModule]
})
export class EcommerceRouting { }
