import {
    transition,
    trigger,
    query,
    style,
    animate,
    group
 } from '@angular/animations';

 export const opacityAnimation =
    trigger('routeAnimations', [
        transition('Login <=> ResetPassword', [
            query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
            group([
                query(':enter', [
                    style({ transform: 'translateX(-100%)' }),
                    animate('0.3s ease-in-out', style({ transform: 'translateX(0)' }))
                ], { optional: true }),
                query(':leave', [
                    style({ transform: 'translateX(0)' }),
                    animate('0.3s ease-in-out', style({ transform: 'translateX(100%)' }))
                ], { optional: true }),
            ])
        ]),
    ]);
