import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.pug',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public currentDate: Date;
  
  constructor() {
    this.currentDate = new Date();
  }
}
