import { Component, OnInit } from '@angular/core';
import { AuthService, FeedbackService } from '../../_services';
import { getCookie } from '../../_helpers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.pug',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAdmin:             boolean = false;
  isLoggedIn:          boolean = false;
  isExpiringSoon:      boolean = false;
  acctExpDate:         string = '';

  expType:             string;
  expMessage:          string;

  showExpireBar:       boolean = false;

  constructor(private auth: AuthService,
              private feedbackSrv: FeedbackService) { }

  ngOnInit() {
    this.auth.isAdmin.subscribe((isAdmin: boolean) => this.isAdmin = isAdmin);
    this.auth.isLoggedIn.subscribe((isLoggedIn: boolean) => this.isLoggedIn = isLoggedIn);
    this.auth.isExpiringSoon.subscribe((isExpiringSoon: boolean) => this.isExpiringSoon = isExpiringSoon);
    this.auth.acctExpDate.subscribe((acctExpDate: string) => this.acctExpDate = acctExpDate);

    this.auth.expType.subscribe((expType: string) => {
      this.expType = expType;

      // get appropriate expiration message
      if ('' !== this.expType && undefined !== this.expType) {
        this.feedbackSrv.getExpiringSoonMsgByType(this.expType)
          .subscribe(
            (res) => {
              this.expMessage = res.message;
              this.manageExpirationNotice();
            },
            (err) => { console.log(err) }
          );
      }
    });
  }

  public manageExpirationNotice(dismiss = false) {
    if (dismiss) {
      document.cookie = `${this.expType}_expiring=hide; path=/`;
      this.showExpireBar = false;
    } else {

      let cookie = getCookie(`${this.expType}_expiring`);

      if (undefined === cookie) {
        document.cookie = `${this.expType}_expiring=show; path=/`;
        cookie = 'show';
      }

      if ('show' === cookie) {
        this.showExpireBar = true;
      } else if ('hide' === cookie) {
        this.showExpireBar = false;
      }
    }
  }

  public logout() { this.auth.logout(); }
}
