import { Component, OnInit, OnDestroy } from '@angular/core';
import { WorkbookService, FeedbackService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { AdditionalTopicStem } from '../../_models';

@Component({
  selector: 'workbook-additional-topic-introduction',
  templateUrl: './workbook-additional-topic-introduction.component.pug',
  styleUrls: ['./workbook-additional-topic-introduction.component.scss']
})
export class WorkbookAdditionalTopicIntroductionComponent implements OnInit, OnDestroy {
  public feedbackSubscription: Subscription;
  public feedback: string;
  public topic$: Observable<AdditionalTopicStem>;

  constructor(private workbookSrv: WorkbookService,
              private route: ActivatedRoute,
              private feedbackSrv: FeedbackService) {
    this.feedbackSubscription = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
    this.feedback = 'none';
  }

  ngOnInit() {
    this.feedback = 'loading';
    const topicId = this.route.snapshot.paramMap.get('id');
    this.topic$ = this.workbookSrv.getAdditionalTopic(+topicId);
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
