import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../_shared/shared.module';

import { OnlineExamRouting } from './online-exam.routing';

import { OnlineExamListingComponent } from './online-exam-listing/online-exam-listing.component';
import { BlockListingComponent } from './block-listing/block-listing.component';



@NgModule({
  declarations: [
    OnlineExamListingComponent,
    BlockListingComponent,
  ],
  imports: [
    CommonModule,
    OnlineExamRouting,
    NgbPopoverModule,
    SharedModule,
  ]
})
export class OnlineExamModule { }
