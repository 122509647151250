import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, AfterViewInit, OnDestroy, ViewChild, Input, Inject } from '@angular/core';
import {
  deleteDomainCookies,
  setCookies,
  CF_MEDIA_DOMAIN_PROD,
  CF_MEDIA_DOMAIN_STAGE,
  getCloudfrontEnvironment,
  HLS_MAX_BUFFER_LENGTH,
  HLS_MAX_BUFFER_SIZE
} from 'src/app/_helpers';
import { SignedCookies, Slide } from 'src/app/_models';
import { SlideService } from 'src/app/_services';
import * as Hls from 'hls.js';
import { Observable } from 'rxjs';

@Component({
  selector: 'slide-deck',
  templateUrl: './slide-deck.component.pug',
  styleUrls: ['./slide-deck.component.scss']
})

export class SlideDeckComponent implements OnInit {
  private LOCAL_STORAGE_PRES_TYPE: string = 'presentation_type';

  public deckTitle = null;
  public currentSlide;
  public currentSlideIdx;
  public errorMessage = null;
  public useAudio: boolean = false;

  private CF_ENV;
  private hls;

  @Input() slides: Slide[];
  @Input() deckId;
  @ViewChild('videoMedia', {static: false}) videoMedia: ElementRef;
  @ViewChild('imageMedia', {static: false}) imageMedia: ElementRef;
  @ViewChild('audioMedia', {static: false}) audioMedia: ElementRef;

  constructor(private slideSrv: SlideService,
              @Inject(DOCUMENT) private document: any) {
    this.CF_ENV = getCloudfrontEnvironment();

    // determine if the user by default wishes to listen to slides
    if ('audio' === localStorage.getItem(this.LOCAL_STORAGE_PRES_TYPE)) {
      this.useAudio = true;
    } else {
      localStorage.setItem(this.LOCAL_STORAGE_PRES_TYPE, 'video');
    }
  }

  ngOnInit() {
    if (undefined !== this.slides[0]) {
      this.currentSlide    = this.slides[0];
      this.currentSlideIdx = 0;

      if (/^\d+$/.test(this.deckId)) {
        this.slideSrv.getDeckTitle(this.deckId).subscribe(res => this.deckTitle = res.title);
      } else {
        this.deckTitle = this.deckId.replaceAll("-", " ").toUpperCase();
      }
    } else {
      this.currentSlide    = null;
      this.currentSlideIdx = null;
    }
  }

  setCurrentSlide(slide: Slide) {
    const requestedSlideIdx = this.slides.findIndex(obj => obj.id === slide.id);

    if (-1 !== requestedSlideIdx) {
      this.currentSlide    = this.slides[requestedSlideIdx];
      this.currentSlideIdx = requestedSlideIdx;
      this.navigateBasedOnPresentationType(this.gotoNextSlide.bind(this));
    } else {
      return false;
    }
  }

  gotoPreviousSlide() {
    if (this.currentSlideIdx - 1 >= 0) {
      this.currentSlideIdx--;
      this.currentSlide = this.slides[this.currentSlideIdx];

      this.navigateBasedOnPresentationType(this.gotoPreviousSlide.bind(this));
    }
  }

  gotoNextSlide() {
    if (this.currentSlideIdx + 1 < this.slides.length) {
      this.currentSlideIdx++;
      this.currentSlide = this.slides[this.currentSlideIdx];

      this.navigateBasedOnPresentationType(this.gotoNextSlide.bind(this));
    }
  }

  togglePresentationType() {
    if ('audio' === localStorage.getItem(this.LOCAL_STORAGE_PRES_TYPE)) {
      localStorage.setItem(this.LOCAL_STORAGE_PRES_TYPE, 'video');
      this.useAudio = false;
    } else {
      localStorage.setItem(this.LOCAL_STORAGE_PRES_TYPE, 'audio');
      this.useAudio = true;
    }

    window.location.reload();
  }

  ngAfterViewInit() {
    if (null !== this.currentSlide) {
      this.navigateBasedOnPresentationType(this.gotoNextSlide.bind(this));
    }
  }

  ngOnDestroy() {
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
  }

  private navigateBasedOnPresentationType(fn) {
    if (this.useAudio) {
      if (this.currentSlide.media.type !== 'image') {
        this.initSlideMediaRequest(this.currentSlide);
      } else {
        fn();
      }
    } else {
      this.initSlideMediaRequest(this.currentSlide);
    }
  }

  private initSlideMediaRequest(slide: Slide) {
    this.errorMessage = null;

    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }

    if (this.useAudio && 'image' === slide.media.type) {
      return this.gotoNextSlide();
    } else {
      this.slideSrv.getSignedCookies(slide.id).subscribe(
        (cookies: SignedCookies) => {
          deleteDomainCookies(this.document);
          setCookies(this.document, cookies);

          const manifestRoot = this.CF_ENV === 'stage' ? CF_MEDIA_DOMAIN_STAGE : CF_MEDIA_DOMAIN_PROD;

          let manifest;
          let av = null;

          if ('image' === slide.media.type) {
            manifest = `${manifestRoot}/image_${slide.media.guid}/media.jpg`;
            this.imageMedia.nativeElement.src = manifest;
          } else {
            if (this.useAudio) {
              manifest = `${manifestRoot}/video_${slide.media.guid}/audio.m3u8`;
              av = this.audioMedia.nativeElement;
            } else {
              manifest = `${manifestRoot}/video_${slide.media.guid}/master.m3u8`;
              av = this.videoMedia.nativeElement;
            }
          }

          if ('image' !== slide.media.type) {
            if (av.canPlayType('application/vnd.apple.mpegurl')) {
              av.src = manifest;
              av.addEventListener('loadedmetadata', () => av.play());
            } else if (Hls.isSupported()) {
              this.hls = new Hls({
                maxBufferLength: HLS_MAX_BUFFER_LENGTH,
                maxBufferSize: HLS_MAX_BUFFER_SIZE,
                xhrSetup: function(xhr, url) {
                  xhr.withCredentials = true;
                }
              });

              this.hls.loadSource(manifest);
              this.hls.attachMedia(av);
              this.hls.on(Hls.Events.MANIFEST_PARSED, () => av.play());
            }
          }
        },
        (err: HttpErrorResponse) => {
          if (404 === err.status) {
            this.errorMessage = 'Slide does not have associated media. Please check back later.';
          } else if (400 === err.status) {
            this.errorMessage = 'Slide is not currently available. Please check back later.';
          } else if (500 === err.status) {
            this.errorMessage = 'Error retrieving slide. Please contact customer support.';
          }
        }
      );
    }
  }
}
