import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FeedbackService } from 'src/app/_services';

@Component({
  selector: 'account-expire',
  templateUrl: './account-expire.component.pug',
  styleUrls: ['./account-expire.component.scss']
})
export class AccountExpireComponent implements OnInit {
  public message$: Observable<{message: string}>

  constructor(private route: ActivatedRoute,
              private feedbackSrv: FeedbackService) { }

  ngOnInit() {
    const expirationType = this.route.snapshot.paramMap.get('type');
    this.message$        = this.feedbackSrv.getExpiringSoonMsgByType(expirationType);
  }

}
