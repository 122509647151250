import { Component, Input } from '@angular/core';

@Component({
  selector: 'feedback',
  templateUrl: './user-feedback.component.pug',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent {
  @Input() type: string;
  @Input() icon: string;
  @Input() messageDenied: string = 'you are not granted access to this asset';
  @Input() messageError: string = 'error retrieving asset';
}
