import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { noSort } from 'src/app/_helpers';
import { AccountOwnedProducts } from 'src/app/_models';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'account-products',
  templateUrl: './account-products.component.pug',
  styleUrls: ['./account-products.component.scss']
})
export class AccountProductsComponent implements OnInit {
  public showNoProductsMessage: boolean = false;
  public noSort = noSort;
  public ownedProducts: AccountOwnedProducts;

  constructor(private accountSrv: AccountService) { }

  ngOnInit() {
    this.accountSrv.getOwnedProducts().subscribe(
      res => {this.ownedProducts = res},
      err => {this.showNoProductsMessage = true}
    );
  }
}
