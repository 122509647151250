import { PipeTransform, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';

@Pipe({name: 'safeHTML'})
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: any) {
        const sanitized = DOMPurify.sanitize(value);
        return this.sanitizer.bypassSecurityTrustHtml(sanitized);
    }
}
