import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PageScrollService } from "ngx-page-scroll-core";
import {
  getStates,
  getTimeZones,
  getClassDesignations,
  mustMatchValidator,
  noSort,
} from "../_helpers";
import quintEasing from "../_helpers/helpers-easing";
import { AccountManage, SmallGroup } from "../_models";
import { SmallGroupService } from "../_services";
import { AccountService } from "../_services/account.service";
import { AuthService } from "../_services/auth.service";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

@Component({
  selector: "account",
  templateUrl: "./account.component.pug",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  public userForm: FormGroup;
  public passwordForm: FormGroup;
  public readonly states: string[];
  public readonly timezones: string[];
  public readonly classDesignations: string[];
  public examDate: Date;
  public expirationDate: Date;
  public userName: string;
  public coinCount: number;
  public smallGroupReservations$: Observable<SmallGroup[]>;
  public updatingAccount: boolean;
  public updatingPassword: boolean;
  public errors;
  public errorsPassword;
  public showErrors: boolean = false;
  public showSuccessMessage: boolean = false;
  public showErrorsPassword: boolean = false;
  public showSuccessMessagePassword: boolean = false;
  public noSort = noSort;

  public showExamNavigation: boolean = false;
  public showBuyCoinsNavigation: boolean = false;

  public sgRetreivalErr: boolean = false;
  public sgRetreivalNull: boolean = false;

  constructor(
    private accountSrv: AccountService,
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private scroller: PageScrollService,
    private authSrv: AuthService,
    private smallGroupSrv: SmallGroupService
  ) {
    this.states = getStates();
    this.timezones = getTimeZones();
    this.classDesignations = getClassDesignations();
  }

  ngOnInit() {
    this.accountSrv.getAccount().subscribe((account: AccountManage) => {
      this.examDate = this.processDate(account.examDate);
      this.expirationDate = this.processDate(account.expirationDate);

      this.userName = account.email;

      this.userForm = new FormGroup({
        firstName: new FormControl(account.firstName, Validators.required),
        lastName: new FormControl(account.lastName, Validators.required),
        email: new FormControl(null, [Validators.email]),
        phoneNumber: new FormControl(account.phoneNumber, [
          Validators.required,
          Validators.pattern("^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$"),
        ]),
        address1: new FormControl(account.address1, Validators.required),
        address2: new FormControl(account.address2),
        city: new FormControl(account.city, Validators.required),
        state: new FormControl(account.state, Validators.required),
        zipCode: new FormControl(account.zipCode, [
          Validators.required,
          Validators.pattern("[0-9]{5}"),
        ]),
        timezone: new FormControl(account.timezone, Validators.required),
        classDesignation: new FormControl(
          account.grade ? account.grade.name : null
        ),
      });
    });

    this.passwordForm = new FormGroup(
      {
        password: new FormControl(null, [
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*()_+=?])[-A-Za-z0-9!@#$%^&*()_+=?]{12,}$"
          ),
        ]),
        matchPassword: new FormControl(null),
      },
      mustMatchValidator("password", "matchPassword")
    );

    this.getStudentReservations();
    this.getCoinCount();
  }

  public onPasswordSubmit() {
    this.updatingPassword = true;

    this.accountSrv.resetPasswordLoggedIn(this.passwordForm.value).subscribe(
      (res) => {
        this.updatingPassword = false;
        this.showErrorsPassword = false;
        this.showSuccessMessagePassword = true;

        this.scroller.scroll({
          document: this.document,
          scrollTarget: "#successPassword",
          duration: 700,
          easingLogic: quintEasing,
          scrollOffset: 25,
        });

        setTimeout(() => {
          this.authSrv.logout();
        }, 2000);
      },
      (err) => {
        this.updatingPassword = false;

        if (400 === err.status) {
          this.authSrv.logout();
        } else if (422 === err.status) {
          this.errorsPassword = err.error;
          this.showErrorsPassword = true;
          this.showSuccessMessagePassword = false;

          this.scroller.scroll({
            document: this.document,
            scrollTarget: "#errorsPassword",
            duration: 700,
            easingLogic: quintEasing,
            scrollOffset: 25,
          });
        }
      }
    );
  }

  public onSubmit() {
    this.updatingAccount = true;

    this.accountSrv.updateAccount(this.userForm.value).subscribe(
      (res) => {
        this.updatingAccount = false;
        this.showErrors = false;
        this.showSuccessMessage = true;

        this.scroller.scroll({
          document: this.document,
          scrollTarget: "#success",
          duration: 700,
          easingLogic: quintEasing,
        });

        setTimeout(() => {
          this.router.navigate(["/"]);
        }, 6000);
      },
      (err) => {
        this.updatingAccount = false;

        if (400 === err.status) {
          this.router.navigate(["/"]);
        } else if (422 === err.status) {
          this.errors = err.error;
          this.showSuccessMessage = false;
          this.showErrors = true;

          this.scroller.scroll({
            document: this.document,
            scrollTarget: "#errors",
            duration: 700,
            easingLogic: quintEasing,
            scrollOffset: 25,
          });
        }
      }
    );
  }

  public getCoinCount() {
    this.accountSrv.getCoinCount().subscribe((count) => {
      this.coinCount = count.coinCount;

      if (this.coinCount > 0) {
        this.showExamNavigation = true;
      } else {
        this.showBuyCoinsNavigation = true;
      }
    });
  }

  public getStudentReservations() {
    this.authSrv.reauthenticate().subscribe((authenticated) => {
      if (authenticated) {
        this.smallGroupReservations$ = this.smallGroupSrv
          .getStudentReservations()
          .pipe(
            map((reservations) => {
              if (null === reservations) this.sgRetreivalNull = true;
              return reservations;
            }),
            catchError((err: HttpErrorResponse) => {
              this.sgRetreivalErr = true;
              return throwError(err);
            })
          );
      }
    });
  }

  public cancelSmallGroupReservations(groupId: number) {
    Confirm.show(
      "Delete Reservation?",
      "Do you want to delete this reservation?",
      "yes",
      "cancel",
      () => {
        this.smallGroupSrv
          .cancelReservation(groupId)
          .pipe(
            map((resp) => {
              if (null === resp) {
                Notify.success("You successfully canceled your reservation.", {
                  position: "center-center",
                  timeout: 3000,
                  width: "480",
                  backOverlay: true,
                });
              }
              return resp;
            }),
            catchError((err: HttpErrorResponse) => {
              if (498 === err.status) {
                Notify.info(
                  "Cancellation time must be greater than 24hrs from group start time.",
                  {
                    position: "center-center",
                    timeout: 5000,
                    width: "480",
                    backOverlay: true,
                    closeButton: true,
                  }
                );
              } else {
                Notify.failure("System error. Contact customer support.", {
                  position: "center-center",
                  timeout: 5000,
                  width: "480",
                  backOverlay: true,
                });
              }
              return throwError(err);
            })
          )
          .subscribe(() => {
            this.getStudentReservations();
            this.getCoinCount();
          });
      }
    );
  }

  /*************************** PRIVATE HELPERS */
  /*********************************************/

  private processDate(date: string) {
    if (date) {
      let dateOnly = date.split("T")[0];
      return new Date(`${dateOnly} CST`);
    }

    return null;
  }
}
