import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'lowercaseHyphenate'
})
export class LowercaseHyphensPipe implements PipeTransform {
    transform(value: any) {
        const noSpaces = value.replace(/\s+/g, '-');
        return noSpaces.toLowerCase();
    }
}
