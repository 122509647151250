import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards';

import { WorkbookComponent } from './workbook.component';
import { WorkbookBeginComponent } from './workbook-begin/workbook-begin.component';
import { WorkbookShortFormComponent } from './workbook-short-form/workbook-short-form.component';
import { WorkbookLongFormComponent } from './workbook-long-form/workbook-long-form.component';
import { WorkbookKeyTopicComponent } from './workbook-key-topic/workbook-key-topic.component';
import { WorkbookOperativeManagementComponent } from './workbook-operative-management/workbook-operative-management.component';
import { WorkbookAdditionalTopicIntroductionComponent } from './workbook-additional-topic-introduction/workbook-additional-topic-introduction.component';
import { WorkbookCaseScenarioComponent } from './workbook-case-scenario/workbook-case-scenario.component';

const ROUTES_WORKBOOK: Routes = [
    {
        path: 'workbook-begin',
        component: WorkbookBeginComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'workbook',
        component: WorkbookComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: ':type/:id/short-form',
                component: WorkbookShortFormComponent
            },
            {
                path: ':type/:id/long-form',
                component: WorkbookLongFormComponent
            },
            {
                path: ':type/:id/key-topics',
                component: WorkbookKeyTopicComponent
            },
            {
                path: ':type/:id/operative-managements/:managementType',
                component: WorkbookOperativeManagementComponent
            },
            {
                path: ':type/:id/at-introduction',
                component: WorkbookAdditionalTopicIntroductionComponent
            },
            {
                path: ':type/:id/questions',
                component: WorkbookCaseScenarioComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES_WORKBOOK)],
    exports: [RouterModule]
})
export class WorkbookRouting { }
