import { Component, Input, AfterViewInit, OnInit, Output, EventEmitter, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { EcommerceProduct } from 'src/app/_models';
import { noSort } from 'src/app/_helpers';

@Component({
  selector: 'subscription-interval-picker',
  templateUrl: './subscription-interval-picker.component.pug',
  styleUrls: ['./subscription-interval-picker.component.scss']
})
export class SubscriptionIntervalPickerComponent implements OnInit, AfterViewInit {

  @Input()  item: EcommerceProduct;
  @Output() intervalChosen: EventEmitter<{uuid: string, interval: number}> = new EventEmitter;
  @ViewChildren('intervalButtons', { read: ElementRef }) intervalButtons: QueryList<ElementRef>;

  public noSort: any = noSort;

  public cssIntervalButtonPrefix: string     = '.interval-button';
  public idIntervalButtonPrefix: string      = 'interval-button-';
  public idSubscriptionTotalPrefix: string   = 'subscription-total-';
  public idSubscriptionPerDayPrefix: string  = 'subscription-per-day-';
  public idSubscriptionSavingsPrefix: string = 'subscription-savings-';

  private baseCostPerDay: number;
  private uuid: string;

  /**
   * As soon as @Input is available, store uuid and
   * calculate the base per day value
   */
  ngOnInit() {
    this.uuid = this.item.uuid;
    this.cssIntervalButtonPrefix = `.interval-button-${this.uuid}`

    // calculate the base per day for this set of intervals
    this.item.subscriptionIntervals.forEach(subscriptionInterval => {
      if (subscriptionInterval.isBase) {
        this.baseCostPerDay = +(Math.round(((+subscriptionInterval.price / subscriptionInterval.interval) * 100)) / 100).toFixed(2);
      }
    })
  }

  /**
   * After the view is available, calculate savings and cost
   * per day on each interval that is selected, which to begin
   * with will always be the interval marked as "best value"
   */
  ngAfterViewInit() {
    const intervalButtons = this.intervalButtons.toArray();

    intervalButtons.forEach(btn => {
      if (btn.nativeElement.classList.contains('selected')) {
        const { cost, interval } = this.getIntervalButtonDataAttrs(btn.nativeElement);
        this.calculateCostAndSavings(+cost, +interval);
      }
    });
  }

  /**
   * Update the view to show the currently selected interval
   * and calculate the savings from the button chosen
   */
  public updateIntervalSavingsView(e: MouseEvent) {
    const { id, cost, interval } = this.getIntervalButtonDataAttrs(e.currentTarget as HTMLElement);

    // reset all buttons in current product box
    const clickedButton = document.getElementById(id);
    const parentBox     = clickedButton.parentElement;
    const children      = parentBox.children;

    for (let i = 0; i < children.length; i++) {
      if (children[i].classList.contains('selected')) children[i].classList.remove('selected');
      if (children[i] === clickedButton) children[i].classList.add('selected');
    }

    this.calculateCostAndSavings(+cost, +interval)
  }

  public calculateCostAndSavings(cost: number, interval: number) {
    // update parent with chosen interval
    this.intervalChosen.emit({uuid: this.uuid, interval: interval});

    const totalBox   = document.getElementById(this.idSubscriptionTotalPrefix + this.uuid);
    const perDayBox  = document.getElementById(this.idSubscriptionPerDayPrefix + this.uuid);
    const savingsBox = document.getElementById(this.idSubscriptionSavingsPrefix + this.uuid);

    totalBox.classList.add('dynamic-box-hide');
    perDayBox.classList.add('dynamic-box-hide');
    savingsBox.classList.add('dynamic-box-hide');

    setTimeout(() => {
      const costPerDay    = (Math.round(((cost / interval) * 100)) / 100).toFixed(2)
      const savingsPerDay = Math.round((1 - (Number(costPerDay) / this.baseCostPerDay)) * 100);

      // interval chosen is base per day value
      if (0 === savingsPerDay) {
        savingsBox.parentElement.classList.add('d-none');
      } else {
        savingsBox.parentElement.classList.remove('d-none');
      }

      totalBox.innerText       = String(cost);
      perDayBox.innerText      = costPerDay;
      savingsBox.innerText     = String(savingsPerDay);

      totalBox.classList.remove('dynamic-box-hide');
      perDayBox.classList.remove('dynamic-box-hide');
      savingsBox.classList.remove('dynamic-box-hide');
    }, 500);
  }

  /**
   * Helper method. Return button data attributes
   */
  private getIntervalButtonDataAttrs(button: HTMLElement) {
    return {
      id:              button.id,
      cost:            button.dataset.cost,
      interval:        button.dataset.interval,
      index:           button.dataset.index,
    }
  }

}
