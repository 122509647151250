import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { getToken } from '../_helpers';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {
  constructor(private jwt: JwtHelperService,
              private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
      const token         = getToken();
      const currentRoles  = (this.jwt.decodeToken(token)).roles;
      const expectedRoles = next.data['expectedRoles'];

      let canAccess = false;

      currentRoles.forEach(element => {
        if (expectedRoles.includes(element)) {
          canAccess = true;
        }
      });

      if (canAccess) {
        return true;
      } else {
        this.router.navigate(['/products']);
        return false;
      }
  }
  canActivateChild(next: ActivatedRouteSnapshot,
                   state: RouterStateSnapshot): boolean {
      return this.canActivate(next, state);
  }

}
