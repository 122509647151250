import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailUnsubscribe } from 'src/app/_models/email-unsubscribe.model';
import { EmailService } from 'src/app/_services';
import { UBP_PHONE_NUMBER, UBP_EMAIL_ADDRESS } from '../../_helpers';

@Component({
  selector: 'Email-unsubscribe',
  templateUrl: './unsubscribe.component.pug',
  styleUrls: ['./unsubscribe.component.scss']
})
export class EmailUnsubscribeComponent implements OnInit {
  public unsubscribeError:    boolean = false;
  public showSuccess:         boolean = false;
  public unsubscribeRequest$: Observable<boolean>;
  public supportEmail       = UBP_EMAIL_ADDRESS;
  public supportPhoneNumber = UBP_PHONE_NUMBER;

  constructor(private route: ActivatedRoute,
              private emailSrv: EmailService) { }

  ngOnInit() {
    /** Get the query params */
    let routeParams = this.route.snapshot.queryParams;

    /** Verify that the route has the necessary query params */
    if (this.isInstanceOfEmailUnsubscribe(routeParams)) {
      this.unsubscribeRequest$ = this.emailSrv.unsubscribe(routeParams)
      .pipe(
        map((response) => {
          if (null === response) this.showSuccess = true;
          return true;
        }),
        catchError((err: HttpErrorResponse) => {
          this.unsubscribeError = true;
          return throwError(err);
        })
      );
    } else {
      this.unsubscribeError = true;
    }
  }

  private isInstanceOfEmailUnsubscribe = (params: any): params is EmailUnsubscribe => {
    return 'emailAddress' in params && 'emailId' in params && 'signature' in params;
  }

}
