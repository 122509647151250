import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from 'src/app/_helpers';
import { EcommerceService } from 'src/app/_services';

@Component({
  selector: 'stripe',
  templateUrl: './stripe.component.pug',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
    public initError: boolean = false;

    @ViewChild('stripeSubmit', {static: true}) stripeSubmit: ElementRef;
    @ViewChild('cardError', {static: true}) cardError: ElementRef;
    @ViewChild('paymentForm', {static: true}) paymentForm: ElementRef;
    @ViewChild('stripeSpinner', {static: false}) stripeSpinner: ElementRef;
    @ViewChild('stripeButtonText', {static: false}) stripeButtonText: ElementRef;
    @ViewChild('resultMessage', {static: false}) resultMessage: ElementRef;

    @Input() cartTotal: string;

    constructor(private eCommSrv: EcommerceService,
                private router: Router) { }

    ngOnInit() {
        this.stripeSubmit.nativeElement.disabled = true;
        this.stripeInitialize();
    }

    public stripeInitialize() {
        loadStripe(STRIPE_PUBLIC_KEY).then(stripe => {
            this.eCommSrv.initStripe().subscribe(
                res => {
                    let elements = stripe.elements();

                    let style = {
                        base: {
                            color: "#4e6984",
                            fontFamily: 'Arial, sans-serif',
                            fontSmoothing: "antialiased",
                            fontSize: "16px",
                            "::placeholder": {
                            color: "#4e6984"
                            }
                        },
                        invalid: {
                            fontFamily: 'Arial, sans-serif',
                            color: "#fa755a",
                            iconColor: "#fa755a"
                        }
                    };

                    let card = elements.create("card", { style: style });

                    // Stripe injects an iframe into the DOM
                    card.mount("#card-element");

                    card.on("change", (event) => {
                        // Disable the Pay button if there are no card details in the Element
                        this.stripeSubmit.nativeElement.disabled = event.empty;
                        this.cardError.nativeElement.textContent = event.error ? event.error.message : "";
                    });

                    this.paymentForm.nativeElement.addEventListener("submit", (e) => {
                        e.preventDefault();
                        // Complete payment when the submit button is clicked
                        this.stripePayWithCard(stripe, card, res.clientSecret);
                    });
                },
                err => {
                    this.initError = true;
                }
            );
        });
    }

    /**
     * Calls stripe.confirmCardPayment
     * If the card requires authentication Stripe shows a pop-up modal to
     * prompt the user to enter authentication details without leaving your page.
     */
    private stripePayWithCard (stripe, card, clientSecret) {
        this.stripeLoading(true);

        stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: card }
        }).then((result) => {
            if (result.error) {
                // Show error to your customer
                this.stripeShowError(result.error.message);
            } else {
                // The payment succeeded!
                this.stripeOrderComplete(result.paymentIntent.id);
            }
        });
    }

    /**
     * Shows a success message when the payment is complete
     */
    private stripeOrderComplete (paymentIntentId) {
        this.stripeLoading(false);
        this.resultMessage.nativeElement.classList.remove("hidden");
        this.stripeSubmit.nativeElement.disabled = true;

        setTimeout(() => {
            this.router.navigate(['purchase', paymentIntentId, 'receipt']);
        }, 3000)
    };

    /**
     * Show the customer the error from Stripe if their card fails to charge
     */
    private stripeShowError (errorMsgText) {
        this.stripeLoading(false);

        this.cardError.nativeElement.textContent = errorMsgText;

        setTimeout(() =>{
            this.cardError.nativeElement.textContent = "";
        }, 4000);
    }

    /**
     * Show loading animation
     */
    private stripeLoading (isLoading) {
        if (isLoading) {
            // Disable the button and show a spinner
            this.stripeSubmit.nativeElement.disabled = true;
            this.stripeSpinner.nativeElement.classList.remove("hidden");
            this.stripeButtonText.nativeElement.classList.add("hidden");
        } else {
            this.stripeSubmit.nativeElement.disabled = false;
            this.stripeSpinner.nativeElement.classList.add("hidden");
            this.stripeButtonText.nativeElement.classList.remove("hidden");
        }
    }
}
