import { Component } from '@angular/core';
import { practiceSetConversions } from  '../../../_helpers';

@Component({
  selector: 'practice-set-mapping',
  templateUrl: './sidebar-practice-set-mapping.component.pug',
  styleUrls: ['./sidebar-practice-set-mapping.component.scss']
})
export class SidebarPracticeSetMappingComponent {
  public mappings = practiceSetConversions;

  public noSort = (a, b) => a.key;
}
