import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { WorkbookService, FeedbackService, VideoService } from '../../_services';
import { Subscription, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MockExamLongForm } from '../../_models';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'workbook-long-form',
  templateUrl: './workbook-long-form.component.pug',
  styleUrls: ['./workbook-long-form.component.scss']
})
export class WorkbookLongFormComponent implements OnInit, OnDestroy {
  public feedbackSubscription: Subscription;
  public feedback: string;
  public examId: number;
  public longForm$: Observable<MockExamLongForm>;

  constructor(private workbookSrv: WorkbookService,
              private feedbackSrv: FeedbackService,
              private route: ActivatedRoute,
              private videoSrv: VideoService,
              @Inject(DOCUMENT) private document: any) {
    this.feedbackSubscription = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
    this.feedback = 'none';
  }

  ngOnInit() {
    this.feedback = 'loading';
    this.examId = +this.route.snapshot.paramMap.get('id');

    this.longForm$ = this.workbookSrv.getMockExamLongForm(this.examId);
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
