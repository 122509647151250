import { FormGroup, ValidatorFn, ValidationErrors } from "@angular/forms";
import { SignedCookies } from "../_models";
import { APP_DOMAIN } from "./helpers-http";

export const SILVER_UUID: string = "pkg_silver_package";
export const IDENTIFY_OM: string = "operative-management";
export const IDENTIFY_CS: string = "case-scenario";
export const UBP_PHONE_NUMBER: string[] = ["3167769898", "(316) 776-9898"];
export const UBP_EMAIL_ADDRESS: string = "information@ultimateboardprep.com";
export const CF_AUDIO: string = "https://audio.ultimateboardprep.com";
export const CF_VIDEO: string = "https://video.ultimateboardprep.com";
export const ADMIN_ROLES: string[] = ["ROLE_ADMIN", "ROLE_SUPER_ADMIN"];
export const HLS_MAX_BUFFER_SIZE: number = 30 * 1000 * 1000;
export const HLS_MAX_BUFFER_LENGTH: number = 15;
export const RECAPTCHA_SITE_KEY =
  window.location.host.indexOf(":") !== -1
    ? "6LdMF8oZAAAAAFDqQp7woODqNVSNkTqT9TXdkXxp"
    : "6Lc1xckZAAAAAN7VNM-MAtkj8fPHClMwqp41jF_3";
export const STRIPE_PUBLIC_KEY =
  window.location.host.indexOf(":") !== -1 ||
  window.location.host.indexOf(".stage.") !== -1
    ? "pk_test_51HgytyAUNwq6G5mHMuEfF2K2zpA51oxQh1ncphTebtTS99sSrah6zS8yClbMxOa6O4eSFXr85M9T8kcjxYn3mcD2006sGC9DB9"
    : "pk_live_51HgytyAUNwq6G5mH34yVB6GQa2WGa2JaxFFiQdSrImpqjZlckLxBdWoGGsrcarlYKexAYspClk5jJnS4CW2NvIqa00AUzcAMRt";

export const CF_MEDIA_DOMAIN_PROD: string =
  "https://media.ultimateboardprep.com";
export const CF_MEDIA_DOMAIN_STAGE: string =
  "https://media-stage.ultimateboardprep.com";

export const getCloudfrontEnvironment = () => {
  if (window.location.hostname.indexOf("stage") !== -1) {
    return "stage";
  } else if (
    window.location.hostname.indexOf("localhost") === -1 &&
    window.location.hostname.indexOf("stage") === -1
  ) {
    return "production";
  }
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function noSort(a, b) {
  return a.key;
}

// Get the JWT token form local storage
export function getToken(): string {
  return localStorage.getItem("token");
}

// Get the Refresh token from local storage
export function getRefreshToken(): string {
  return localStorage.getItem("refresh_token");
}

// render an appropriate version of an asset's title
// for encoded URLs
export function renderEncodedUri(assetTitle): string {
  let encodedURI = assetTitle.replace(/#/g, "");
  encodedURI = encodedURI.replace(/\s+/g, "-").toLowerCase();

  return encodeURI(encodedURI);
}

export function deleteDomainCookies(document) {
  var cookies = document.cookie.split("; ");

  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");

    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = location.pathname.split("/");
      document.cookie = cookieBase + "/";

      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }

      d.shift();
    }
  }
}

export function setCookies(d, cookies: SignedCookies) {
  const cookieDetails =
    "; domain=ultimateboardprep.com; path=/; SameSite=Strict; Secure";

  d.cookie = `${cookies.accessKeyId}${cookieDetails}`;
  d.cookie = `${cookies.signature}${cookieDetails}`;
  d.cookie = `${cookies.policy}${cookieDetails}`;
}

export function getClassDesignations(): string[] {
  return ["CA1", "CA2", "CA3", "PGY1", "Post Grad"];
}

export function getTimeZones(): string[] {
  return [
    "Pacific Standard Time",
    "Mountain Standard Time",
    "Central Standard Time",
    "Eastern Standard Time",
    "Hawaii-Aleutian Standard Time",
    "Alaskan Standard Time",
  ];
}

export function getStates(): string[] {
  return [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Puerto Rico",
    "Republic Of Palau",
    "Republic Of The Marshall Islands",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "U.S. Virgin Islands",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
}

export function mustMatchValidator(
  controlName: string,
  matchingControlName: string
): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

function PopupCenter(url, title, w, h) {
  // Fixes dual-screen position                         Most browsers      Firefox
  let dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : 250;
  let dualScreenTop = window.screenTop != undefined ? window.screenTop : 250;

  let width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  let height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  let left = width / 2 - w / 2 + dualScreenLeft;
  let top = height / 2 - h / 2 + dualScreenTop;
  let newWindow = window.open(
    url,
    title,
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left
  );

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export function renderAvWindow(
  type: string,
  src: any,
  directory: string = null
) {
  let encodedType = encodeURI(type);
  let encodedSource = encodeURI(src);

  let url = `${APP_DOMAIN}/av?type=${encodedType}&src=${encodedSource}`;

  if (directory) {
    let encodedDirectory = encodeURI(directory);
    url += `&dir=${encodedDirectory}`;
  }

  PopupCenter(url, "UBP AV", 500, 500);
}

export function renderWorkbookWindow(id: number, isME: boolean) {
  let url = "";

  if (isME) {
    url = `${APP_DOMAIN}/workbook/mock-exam/${id}/short-form`;
  } else {
    url = `${APP_DOMAIN}/workbook/additional-topic/${id}/at-introduction`;
  }

  PopupCenter(url, "UBP Workbook", 960, 500);
}

export function getCookie(cName) {
  const name = cName + "=";
  const decoded = decodeURIComponent(document.cookie); //to be careful
  const cookies = decoded.split("; ");

  let res;

  cookies.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });

  return res;
}
