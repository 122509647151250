import { Component, OnInit, OnDestroy, Inject, AfterViewChecked } from '@angular/core';
import { WorkbookService, FeedbackService, AuthService } from '../../_services';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { WorkbookExamSubpartComponent } from '../workbook-exam-subpart/workbook-exam-subpart.component';
import { IDENTIFY_OM } from '../../_helpers';


@Component({
  selector: 'workbook-operative-management',
  templateUrl: '../workbook-exam-subpart/workbook-exam-subpart.component.pug',
  styleUrls: ['../workbook-exam-subpart/workbook-exam-subpart.component.scss']
})
export class WorkbookOperativeManagementComponent extends WorkbookExamSubpartComponent implements OnInit, OnDestroy, AfterViewChecked {
  private examId: number;
  public highlightId: number;
  public highlightedElement = null;
  public ranHighlighter: boolean = false;

  constructor(protected workbookSrv: WorkbookService,
              protected route: ActivatedRoute,
              protected feedbackSrv: FeedbackService,
              protected scroller: PageScrollService,
              protected authSrv: AuthService,
              @Inject(DOCUMENT) protected document: any,
              protected viewportScroller: ViewportScroller) {
    super(workbookSrv, route, feedbackSrv, scroller, authSrv, document, viewportScroller);
  }

  ngOnInit() {
    this.subPartType = IDENTIFY_OM;
    this.feedback = 'loading';
    this.highlightId = this.route.snapshot.queryParams['highlight'];

    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.fullPart = null;
        this.examId = +params.get('id');
        this.managementType = params.get('managementType');

        this.workbookSrv.getOperativeManagementListing(this.examId, this.managementType).subscribe(
          res => {
            if (null !== res) {
              this.listings = res;
            } else {
              this.listings = [];
            }
          }
        );
      }
    );
  }

  ngAfterViewChecked() {
    if (!this.ranHighlighter) {
      this.highlightedElement = document.getElementById(`subpart-${this.highlightId}`);

      if (null !== this.highlightedElement) {
        this.highlightedElement.classList.add('highlighted');
        this.ranHighlighter = true;
      }
    }
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
