import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { getRequestHelper, postRequestHelper } from '../_helpers';
import { Observable } from 'rxjs';
import { BlockEvent, ExaminerBasicInformation, OnlineExamListing, ReservedExamListing } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class OnlineExamService {
  constructor(private http: HttpClient) { }

  /**
   * Get all the exams requested between a specific date range
   * @param qStr a date range
   */
  public searchExamsByDateRange(qStr: string): Observable<OnlineExamListing[]> {
    const { url } = getRequestHelper(`/online-exam/scheduling/exams/date-range?${qStr}`);
    return this.http.get<OnlineExamListing[]>(url);
  }

  /**
   * Get the exams that have been reserved by a particular student
   */
  public getReservedExams(): Observable<ReservedExamListing> {
    const { url } = getRequestHelper(`/online-exam/scheduling/exams/reserved`);
    return this.http.get<ReservedExamListing>(url);
  }

  public getExaminers(): Observable<ExaminerBasicInformation[]> {
    const { url } = getRequestHelper(`/online-exam/scheduling/examiners`);
    return this.http.get<ExaminerBasicInformation[]>(url);
  }

  /**
   * Reserve an exam by exam id
   * @param examId number
   */
  public reserveExam(examId) {
    const { url, body, headers } = postRequestHelper({ path: `/online-exam/scheduling/exams/${examId}/reserve`, body: null });
    return this.http.post(url, body, {headers: headers});
  }

  /**
   * Get the available block exams times for a block of time
   * @param examId number
   */
  public getAvailableBlocks(examId): Observable<BlockEvent> {
    const { url } = getRequestHelper(`/online-exam/scheduling/exams/${examId}/blocks`);
    return this.http.get<BlockEvent>(url);
  }

  /**
   * Reserve a block of time from a block event
   * @param examId number
   * @param date string
   */
  public reserveBlockExam(examId, date, examType) {
    const { url, body, headers } = postRequestHelper({ path: `/online-exam/scheduling/exams/${examId}/reserve-block`, body: { date: date, type: examType } });
    return this.http.post(url, body, {headers: headers});
  }

  /**
   * Delete an exam by exam id
   * @param examId number
   */
  public cancelExam(examId) {
    const { url } = postRequestHelper({ path: `/online-exam/scheduling/exams/${examId}/cancel` });
    return this.http.delete(url);
  }

  /**
   * Delete a block exam by exam id
   * @param examId number
   */
  public cancelBlockExam(examId) {
    const { url } = postRequestHelper({ path: `/online-exam/scheduling/exams/${examId}/cancel/block` });
    return this.http.delete(url);
  }
}
