import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InstructionManualService } from 'src/app/_services';

@Component({
  selector: 'manual-entry',
  templateUrl: './manual-entry.component.pug',
  styleUrls: ['./manual-entry.component.scss']
})
export class ManualEntryComponent implements OnInit {
  private entryRoute: string;
  public content$;
  public errorMsg: string;
  public showErrorMsg: boolean = false;

  constructor(private route: ActivatedRoute,
              private manualSrv: InstructionManualService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.entryRoute = params.get('route');

        this.content$ = null;

        this.content$ = this.manualSrv.getEntry(this.entryRoute).pipe(
          catchError((err: HttpErrorResponse) => {
            this.showErrorMsg = true;
            if (404 === err.status) {
              this.errorMsg = 'Could not find requested resource. Refresh the page and try again.';
            } else {
              this.errorMsg = 'Server error - try again or call UBP tech support';
            }

            console.log(err);
            return throwError(err);
          })
        );
      }
    );
  }

}
