import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShoppingCart } from 'src/app/_models';
import { EcommerceService } from 'src/app/_services';
import { noSort } from 'src/app/_helpers';

@Component({
  selector: 'ecommerce-checkout',
  templateUrl: './checkout.component.pug',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  public noSort = noSort;
  public cart$: Observable<ShoppingCart>;
  public cartError: string;
  public cart404: string;
  public cartTotal: string;
  public isBlocked: boolean = false;
  public shippingAddressVerified: boolean = false;

  public acknowledgedFollowupComm: boolean = false;

  public expirationMessage$: Observable<{message: string}>;

  constructor(private eCommSrv: EcommerceService) { }

  ngOnInit() {
    this.cart$ = this.eCommSrv.getCart().pipe(
      map(cart => {
        this.cartTotal = cart.total;
        this.isBlocked = cart.isBlocked;
        return cart;
      }),
      catchError((err: HttpErrorResponse) => {
        if (404 === err.status) {
          this.cart404 = 'You do not currently have a shopping cart.';
        } else {
          this.cartError = 'There was an error in retrieving your cart.'
        }

        console.error(err);
        return throwError(err);
      })
    );
  }

  public confirmShippingAddress() {
    this.shippingAddressVerified = true;
    this.isBlocked = true;
  }
}
