import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { FeedbackService } from './feedback.service';
import { getRequestHelper } from '../_helpers';
import {
  MockExamShortForm,
  MockExamLongForm,
  MockExamKeyTopic,
  ExamSubPart,
  ExamBasicInformation,
  TaggedQuestionResult, AdditionalTopicStem} from '../_models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WorkbookService {

  constructor(private http: HttpClient,
              private feedbackSrv: FeedbackService,
              private router: Router,
              private authSrv: AuthService) { }

  public getExamBasicInformation(examId: number, examType: string): Observable<ExamBasicInformation> {
    const { url } = getRequestHelper(`/workbook/exam/${examType}/${examId}/basic-exam-information`);
    return this.http.get<ExamBasicInformation>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get a listing of workbook parts by type
   * @param type string
   */
  public getExamListingByType(examType: string) {
    const { url } = getRequestHelper(`/workbook/exam/${examType}/listing`);
    return this.http.get(url, {observe: 'response'}).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get mock exam short form and pre-operative questions only
   * @param examId number
   */
  public getMockExamShortForm(examId: number): Observable<MockExamShortForm> {
    const { url } = getRequestHelper(`/workbook/mock-exam/${examId}/short-form`);
    return this.http.get<MockExamShortForm>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get mock exam long form and intra/post-operative questions only
   * @param examId number
   */
  public getMockExamLongForm(examId: number): Observable<MockExamLongForm> {
    const { url } = getRequestHelper(`/workbook/mock-exam/${examId}/long-form`);
    return this.http.get<MockExamLongForm>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get exam key topics
   * @param examId number
   * @param examType string
   */
  public getExamKeyTopics(examId: number, examType: string): Observable<MockExamKeyTopic[]> {
    const { url } = getRequestHelper(`/workbook/exam/${examType}/${examId}/key-topics`);
    return this.http.get<MockExamKeyTopic[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get listing of operative management questions and ids by management type
   * @param examId number
   * @param managementType string
   */
  public getOperativeManagementListing(examId: number, managementType: string):Observable<{id: number, question: string}[]> {
    const { url } = getRequestHelper(`/workbook/mock-exam/${examId}/operative-managements/${managementType}`);
    return this.http.get<{id: number, question: string}[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get data for single pre|intra|post-operative management
   * @param managementId number
   */
  public getSingleExamSubPart(subpartType: string, subPartId: number): Observable<ExamSubPart> {
    const { url } = getRequestHelper(`/workbook/${subpartType}/${subPartId}`);
    return this.http.get<ExamSubPart>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get introductory content for single additional topic
   * @param topicId number
   */
  public getAdditionalTopic(topicId: number): Observable<AdditionalTopicStem> {
    const { url } = getRequestHelper(`/workbook/additional-topic/${topicId}`);
    return this.http.get<AdditionalTopicStem>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get listing of case scenarios and ids
   * @param topicId number
   */
  public getCaseScenarioListing(topicId: number): Observable<{id: number, question: string}[]> {
    const { url } = getRequestHelper(`/workbook/additional-topic/${topicId}/case-scenarios`);
    return this.http.get<{id: number, question: string}[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get messages from examiner for various exam parts
   * @param examType string
   * @param examPart string
   */
  public getExamPartMessage(examType: string, examPart: string): Observable<{message: string}> {
    const { url } = getRequestHelper(`/workbook/exam-general-message/${examType}/${examPart}`);
    return this.http.get<{message: string}>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get a listing of exams included in the UBP Primer
   */
  public getPrimerExamListing() {
    const { url } = getRequestHelper(`/workbook/primer/exam-listing`);
    return this.http.get(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get listing of all workbook tags
   */
  public getTagListing(): Observable<{id: number, name: string}[]> {
    const { url } = getRequestHelper(`/workbook/tags`);
    return this.http.get<{id: number, name: string}[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  public getExamQuestionsByTag(tagId: number): Observable<{[type: string]: TaggedQuestionResult[]}> {
    const { url } = getRequestHelper(`/workbook/tags/${tagId}/question-listing`);
    return this.http.get<{[type: string]: TaggedQuestionResult[]}>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * helper error handler
   */
  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      this.router.navigateByUrl('/products');
    } else if (error.status === 401) {
      this.authSrv.logout();
      this.router.navigateByUrl('/login');
    } else {
      this.feedbackSrv.setFeedback('error');
    }

    console.log('[UBP Error] ' + error.message);
    return throwError(error);
  }
}
