import { HttpErrorResponse } from '@angular/common/http';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BlockEvent } from 'src/app/_models';
import { OnlineExamService } from 'src/app/_services';

@Component({
  selector: 'block-listing',
  templateUrl: './block-listing.component.pug',
  styleUrls: ['./block-listing.component.scss']
})
export class BlockListingComponent {
  public blockData: BlockEvent;
  public showEmpty: boolean = false;
  public showSuccess: boolean = false;
  public showErrorClient: boolean = false;
  public showErrorServer: boolean = false;
  public showErrorCoins: boolean = false;
  public processingRequest: boolean = false;

  @Input('isOsceEligible') osceEligible: boolean = false;
  @Output() blockExamReserved: EventEmitter<boolean> = new EventEmitter();

  constructor(private examSrv: OnlineExamService,
              private router: Router) { }

  public reserveBlock(examId, date, examType) {
    this.showSuccess       = false;
    this.showErrorClient   = false;
    this.showErrorServer   = false;
    this.showErrorCoins    = false;
    this.processingRequest = true;

    this.examSrv.reserveBlockExam(examId, date, examType).subscribe(
      res => {
        this.processingRequest = false;
        this.showSuccess = true;
        console.log('[BlockListingComponent] block exam successfully reserved');
        this.blockExamReserved.emit(true);
      },
      (err: HttpErrorResponse) => {
        this.processingRequest = false;

        if (400 === err.status) {
          this.showErrorClient = true;
          console.log('[BlockListingComponent] could not reserve exam');
        } else if (403 === err.status) {
          this.showErrorCoins  = true;
          console.log('[BlockListingComponent] must possess exam tokens');
          setTimeout(() => this.router.navigate(['products']), 3000);
        } else {
          this.showErrorServer = true;
          console.log('[BlockListingComponent] server error');
        }
      }
    );
  }
}
