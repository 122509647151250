import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_guards';

import { AvComponent } from './av/av.component';
import { DocumentComponent } from './document/document.component';

const ROUTES_SHARED: Routes = [
    {
        path: 'documents/:id',
        component: DocumentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'av',
        component: AvComponent,
        canActivate: [AuthGuard]
    },
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES_SHARED)],
    exports: [RouterModule]
})
export class SharedRouting { }
