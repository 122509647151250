import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { EcommerceItem } from "src/app/_models";
import { EcommerceService } from "src/app/_services";
import { Report } from "notiflix/build/notiflix-report-aio";

@Component({
  selector: "product-widget",
  templateUrl: "./product.component.pug",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  private actionsLocked: boolean;

  @Input() item: EcommerceItem;
  @Input() type: string;
  @Input() category: string;

  public quantity: number = 1;
  public chosenSubscriptionIntervals: { [uuid: string]: number };

  public constructor(private eCommSrv: EcommerceService) {}

  ngOnInit() {
    this.eCommSrv.cartActionsLocked.subscribe(
      (locked) => (this.actionsLocked = locked)
    );
  }

  /**
   * Add item to shopping cart. Non-subscription
   * items will be considered a 'regular' item.
   */
  public addToCart(item: EcommerceItem, regular = true) {
    if (!this.actionsLocked) {
      this.eCommSrv.utilSetLockCartActions(true);

      const ubpItem = item.package ? item.package : item.product;

      if (regular) {
        // item is an auto upgrade product (i.e. Workbood to Silver Plan)
        if (ubpItem.hasOwnProperty("autoUpgradeUuid")) {
          this.eCommSrv.addToCart(item.product.autoUpgradeUuid, 1).subscribe(
            (res) => {
              this.eCommSrv.utilSetCartAlertType("alert-success");
              this.eCommSrv.utilSetCartMessage(item.product.autoUpgradeMessage);
              this.eCommSrv.utilSetCartUpdated(true);
            },
            (err: HttpErrorResponse) => this.setErrorMessage(err.status)
          );
        } else {
          this.eCommSrv.addToCart(ubpItem.uuid, this.quantity).subscribe(
            (res) => {
              this.eCommSrv.utilSetCartAlertType("alert-success");
              this.eCommSrv.utilSetCartMessage("item successfully added");
              this.eCommSrv.utilSetCartUpdated(true);
            },
            (err: HttpErrorResponse) => this.setErrorMessage(err.status)
          );
        }
      } else {
        if (
          ubpItem.hasOwnProperty("hasSubscriptionOptions") &&
          item.product.hasSubscriptionOptions &&
          this.chosenSubscriptionIntervals.hasOwnProperty(ubpItem.uuid)
        ) {
          this.eCommSrv
            .addSubscriptionToCart(
              ubpItem.uuid,
              this.chosenSubscriptionIntervals[ubpItem.uuid]
            )
            .subscribe(
              (res) => {
                this.eCommSrv.utilSetCartAlertType("alert-success");
                this.eCommSrv.utilSetCartMessage("item successfully added");
                this.eCommSrv.utilSetCartUpdated(true);
              },
              (err: HttpErrorResponse) => this.setErrorMessage(err.status)
            );
        } else {
          console.error(
            "[EcommerceService::addSubscriptionToCart] could not add subscription interval to cart"
          );
        }
      }
    }
  }

  public changeQuantity(action: string) {
    if ("decrease" === action) {
      this.quantity--;
      // quantity cannot be less than 1
      if (this.quantity < 1) this.quantity = 1;
    } else if ("increase" === action) {
      this.quantity++;
    }
  }

  public captureSubscriptionIntervalChosen(chosenInterval: {
    uuid: string;
    interval: number;
  }) {
    this.chosenSubscriptionIntervals = {
      [chosenInterval.uuid]: chosenInterval.interval,
    };
  }

  public displayPopup(item: EcommerceItem) {
    Report.init({
      svgSize: "35px",
      info: { backOverlayColor: "rgba(0,0,0,.65)" },
    });
    Report.info(item.popupTitle, item.popupMessage, "ok");
  }

  private setErrorMessage(status) {
    this.eCommSrv.utilSetCartAlertType("alert-danger");

    switch (status) {
      case 494:
        this.eCommSrv.utilSetCartMessage("Your subscription is still active.");
        break;
      case 495:
        this.eCommSrv.utilSetCartMessage(
          "This is a lesser package than what you own."
        );
        break;
      case 496:
        this.eCommSrv.utilSetCartMessage(
          "Please remove other packages from your cart first."
        );
        break;
      case 497:
        this.eCommSrv.utilSetCartMessage(
          "This item is already in your cart, individually or as part of a package."
        );
        break;
      case 498:
        this.eCommSrv.utilSetCartMessage(
          "You already own this item and cannot buy it again."
        );
        break;
      case 499:
        this.eCommSrv.utilSetCartMessage("Your cart is locked.");
        break;
      default:
        this.eCommSrv.utilSetCartMessage(
          "Unknown error. Please contact customer support."
        );
        break;
    }

    this.eCommSrv.utilSetCartUpdated(true);
  }
}
