import { Component, Input } from '@angular/core';

@Component({
  selector: 'video-placeholder-column',
  templateUrl: './video-placeholder-column.component.pug',
  styleUrls: ['./video-placeholder-column.component.scss']
})
export class VideoPlaceholderColumnComponent {
  @Input() signedUrl: string;
  @Input() videoTitle: string;
}
