import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PageScrollService, EasingLogic } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { VideoService, FeedbackService } from '../_services';
import { renderAvWindow, renderWorkbookWindow } from '../_helpers';
import quintEasing from '../_helpers/helpers-easing';
import { OnlineCourseGroupListing } from '../_models';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SidebarPulloutComponent } from '../_shared/sidebar-pullout/sidebar-pullout.component';

@Component({
  selector: 'course-videos',
  templateUrl: './course-videos.component.pug',
  styleUrls: ['./course-videos.component.scss']
})
export class CourseVideosComponent implements OnInit {
  public courseListing$;
  public videoListing$:    Observable<OnlineCourseGroupListing>;
  public showVideo404:     boolean = false;
  public showVideo500:     boolean = false;
  public showVideoLoading: boolean = false;
  @ViewChild('sideBar', {static: false}) pullOutList: SidebarPulloutComponent;

  constructor(private videoSrv: VideoService,
              private scroller: PageScrollService,
              @Inject(DOCUMENT) private document: any,) {
  }

  ngOnInit() {
    this.courseListing$ = this.videoSrv.getOnlineCourseGroups();
  }

  public viewPulloutListing(type: string) {
    this.pullOutList.show();
    this.pullOutList.pulloutType = type;
  }

  getVideos(courseId) {
    this.showVideo404     = false;
    this.showVideo500     = false;
    this.showVideoLoading = true;
    this.videoListing$    = null;

    this.videoListing$ = this.videoSrv.getOnlineCourseVideosByGroup(courseId)
      .pipe(
        tap(() => {
          this.scroller.scroll({
            document: this.document,
            scrollTarget: '#videoListing',
            duration: 1500,
            easingLogic: quintEasing
          });
        }),
        catchError( (err: HttpErrorResponse) => {
          this.showVideoLoading = false;

          if (404 === err.status) this.showVideo404 = true;
          if (500 === err.status) this.showVideo500 = true;

          console.error(err);
          return throwError(err.message);
        })
      );
  }

  public initAvWindow(type: string, src: any, directory: string = null) {
    renderAvWindow(type, src, directory);
  }

  public initWbWindow(id: number, isMe: boolean) {
    renderWorkbookWindow(id, isMe);
  }
}
