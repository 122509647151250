import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ExamBasicInformation } from '../_models';

@Component({
  selector: 'workbook',
  templateUrl: './workbook.component.pug',
  styleUrls: ['./workbook.component.scss']
})
export class WorkbookComponent implements OnInit {
  public examId: number;
  public examType: string;
  public examTitle: string = '';
  public examInPrivateOnline: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.firstChild.paramMap.subscribe(
      (params: ParamMap) => {
        this.examId = +params.get('id');
        this.examType = params.get('type');
      }
    );
  }

  public setExam(e: ExamBasicInformation) {
    this.examTitle = e.title;
    this.examInPrivateOnline = e.inPrivateOnline;
  }
}
