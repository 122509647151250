import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'sidebar-pullout',
  templateUrl: './sidebar-pullout.component.pug',
  styleUrls: ['./sidebar-pullout.component.scss'],
  animations: [
    trigger('pullout', [
      state('show', style({right: '0'})),
      state('hide', style({right: '-700px'})),
      transition('show <=> hide', animate(".3s ease-in-out")),
    ])
  ]
})
export class SidebarPulloutComponent {
  public visibility: string = 'hide';
  public pulloutType: string = '';
  @Input() tagId: number;

  public hide() {
    this.visibility = 'hide';
    this.pulloutType = '';
  }

  public show() {
    this.visibility = 'show';
  }

}
