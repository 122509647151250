import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { getRequestHelper, postRequestHelper } from "../_helpers";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";
import { SmallGroup } from "../_models";

@Injectable({ providedIn: "root" })
export class SmallGroupService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authSrv: AuthService
  ) {}

  public getSmallGroups(): Observable<SmallGroup[]> {
    const { url } = getRequestHelper(`/small-groups`);
    return this.http.get<SmallGroup[]>(url).pipe(catchError(this.handleError));
  }

  public getStudentReservations(): Observable<SmallGroup[]> {
    const { url } = getRequestHelper(`/small-groups/reservations`);
    return this.http.get<SmallGroup[]>(url).pipe(catchError(this.handleError));
  }

  public createReservation(groupId: number) {
    const { url, body, headers } = postRequestHelper({
      path: `/small-groups/${groupId}`,
      body: null,
    });

    return this.http
      .put(url, body, { headers: headers })
      .pipe(catchError(this.handleError));
  }

  public cancelReservation(groupId: number) {
    const { url } = postRequestHelper({
      path: `/small-groups/reservations/${groupId}`,
    });
    return this.http.delete(url);
  }

  /** helper error handler */
  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      this.router.navigateByUrl("/products");
    } else if (error.status === 401) {
      this.authSrv.logout();
      this.router.navigateByUrl("/login");
    }

    console.error("[Small Group Service] " + error.message);

    return throwError(error);
  };
}
