import { Component } from '@angular/core';
import { opacityAnimation } from './app.animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [opacityAnimation]
})
export class AppComponent { }
