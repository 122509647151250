import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Hls from 'hls.js';

import {
  CF_AUDIO,
  CF_VIDEO,
  deleteDomainCookies,
  HLS_MAX_BUFFER_LENGTH,
  HLS_MAX_BUFFER_SIZE,
  setCookies } from '../../_helpers';
import { SignedCookies } from '../../_models';
import { VideoService } from '../../_services';

@Component({
  selector: 'av',
  templateUrl: './av.component.pug',
  styleUrls: ['./av.component.scss']
})
export class AvComponent implements OnInit, AfterViewInit, OnDestroy {

  public src: any;
  public type: string;
  public directory: string;
  private manifest: string;
  private hls;

  @ViewChild('ubpVideo', {static: false}) ubpVideo: ElementRef;
  @ViewChild('ubpAudio', {static: false}) ubpAudio: ElementRef;

  constructor(private route: ActivatedRoute,
              private videoSrv: VideoService,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    this.src       = this.route.snapshot.queryParamMap.get('src');
    this.type      = this.route.snapshot.queryParamMap.get('type');
    this.directory = this.route.snapshot.queryParamMap.get('dir');
  }

  ngAfterViewInit() {
    // Don't run if these query params are not present
    if (('video' === this.type || 'audio' === this.type) && this.src && this.directory) {
      this.videoSrv.getSignedCookies(this.src, this.type, this.directory).subscribe(
        (cookies: SignedCookies) => {
          deleteDomainCookies(this.document);
          setCookies(this.document, cookies);

          let av = null;

          if ('video' === this.type) {
            this.manifest = `${CF_VIDEO}/${this.directory}/${this.src}/${this.src}.m3u8`;
            av = this.ubpVideo.nativeElement;
          } else if ('audio' === this.type) {
            this.manifest = `${CF_AUDIO}/${this.directory}/${this.src}/${this.src}-audio.m3u8`;
            av = this.ubpAudio.nativeElement;
          }

          if (av.canPlayType('application/vnd.apple.mpegurl')) {
            av.src = this.manifest;
            av.addEventListener('loadedmetadata', () => av.play());
          } else if (Hls.isSupported()) {
            this.hls = new Hls({
              maxBufferLength: HLS_MAX_BUFFER_LENGTH,
              maxBufferSize: HLS_MAX_BUFFER_SIZE,
              xhrSetup: function(xhr, url) {
                xhr.withCredentials = true;
              }
            });

            this.hls.loadSource(this.manifest);
            this.hls.attachMedia(av);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => av.play());
          }
        }
      );
    }
  }

  public setVideoPlayback(speed) {
    this.ubpVideo.nativeElement.playbackRate = speed;
  }
  public setAudioPlayback(speed) {
    this.ubpAudio.nativeElement.playbackRate = speed;
  }

  ngOnDestroy() {
    if (this.hls) {
      this.hls.stopLoad();
      this.hls.destroy();
    }
  }

}
