import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./_guards/auth.guard";

import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { OsceComponent } from "./osce/osce.component";
import { OsceTopicComponent } from "./osce/osce-topic/osce-topic.component";
import { OsceSlideDeckComponent } from "./osce/osce-slide-deck/osce-slide-deck.component";
import { AccountComponent } from "./account/account.component";
import { AppliedBeginComponent } from "./applied-begin/applied-begin.component";
import { CourseVideosComponent } from "./course-videos/course-videos.component";
import { AccountCreateComponent } from "./account/account-create/account-create.component";
import { AccountVerifyComponent } from "./account/account-verify/account-verify.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { ManualComponent } from "./manual/manual.component";
import { ManualIndexComponent } from "./manual/manual-index/manual-index.component";
import { ManualEntryComponent } from "./manual/manual-entry/manual-entry.component";
import { FreeMaterialComponent } from "./free-material/free-material.component";
import { AccountExpireComponent } from "./account/account-expire/account-expire.component";
import { MediaSlideDeckComponent } from "./media-slide-deck/media-slide-deck.component";
import { SmallGroupComponent } from "./small-group/small-group/small-group.component";
import { EmailUnsubscribeComponent } from "./email/unsubscribe/unsubscribe.component";

const ROUTES_APP: Routes = [
  {
    path: "",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "account/create",
    component: AccountCreateComponent,
  },
  {
    path: "account/create/verify",
    component: AccountVerifyComponent,
  },
  {
    path: "email/unsubscribe",
    component: EmailUnsubscribeComponent,
  },
  {
    path: "account/edit",
    canActivate: [AuthGuard],
    component: AccountComponent,
  },
  {
    path: "account/expire/:type",
    canActivate: [AuthGuard],
    component: AccountExpireComponent,
  },
  {
    path: "applied-begin",
    component: AppliedBeginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "course-videos",
    component: CourseVideosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "free",
    component: FreeMaterialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      animation: "Login",
    },
  },
  {
    path: "small-groups",
    component: SmallGroupComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "manual",
    component: ManualComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "",
        component: ManualIndexComponent,
      },
      {
        path: ":route",
        component: ManualEntryComponent,
      },
    ],
  },
  {
    path: "osce",
    component: OsceComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "topics/:section/:topicId/:topicName",
        component: OsceTopicComponent,
      },
    ],
  },
  {
    path: "osce-slide-decks/:deckId",
    component: OsceSlideDeckComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "media-slide-decks/:deckId",
    component: MediaSlideDeckComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: {
      animation: "ResetPassword",
    },
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES_APP, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
