import { Component, OnInit, OnDestroy, Inject, AfterViewChecked } from '@angular/core';
import { WorkbookService, FeedbackService, AuthService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { WorkbookExamSubpartComponent } from '../workbook-exam-subpart/workbook-exam-subpart.component';
import { IDENTIFY_CS } from '../../_helpers';


@Component({
  selector: 'workbook-case-scenario',
  templateUrl: '../workbook-exam-subpart/workbook-exam-subpart.component.pug',
  styleUrls: ['../workbook-exam-subpart/workbook-exam-subpart.component.scss']
})
export class WorkbookCaseScenarioComponent extends WorkbookExamSubpartComponent implements OnInit, OnDestroy, AfterViewChecked {
  public highlightId: number;
  public highlightedElement = null;
  public ranHighlighter: boolean = false;

  constructor(protected workbookSrv: WorkbookService,
              protected route: ActivatedRoute,
              protected feedbackSrv: FeedbackService,
              protected scroller: PageScrollService,
              protected authSrv: AuthService,
              @Inject(DOCUMENT) protected document: any,
              protected viewportScroller: ViewportScroller) {
    super(workbookSrv, route, feedbackSrv, scroller, authSrv, document, viewportScroller);
  }

  ngOnInit() {
    this.subPartType = IDENTIFY_CS;
    this.feedback = 'loading';
    this.highlightId = this.route.snapshot.queryParams['highlight'];

    const examId = this.route.snapshot.paramMap.get('id');

    this.workbookSrv.getCaseScenarioListing(+examId).subscribe(
      res => {
        if (null !== res) {
          this.listings = res;
        } else {
          this.listings = [];
        }
      }
    );
  }

  ngAfterViewChecked() {
    if (!this.ranHighlighter) {
      this.highlightedElement = document.getElementById(`subpart-${this.highlightId}`);

      if (null !== this.highlightedElement) {
        this.highlightedElement.classList.add('highlighted');
        this.ranHighlighter = true;
      }
    }
  }

  ngOnDestroy() {
    this.feedbackSubscription.unsubscribe();
  }
}
