import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OsceService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { renderEncodedUri, } from '../_helpers';
import quintEasing from "../_helpers/helpers-easing";
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'osce',
  templateUrl: './osce.component.pug',
  styleUrls: ['./osce.component.scss']
})
export class OsceComponent implements OnInit {
  public topicsByGroup$: Observable<{[section: string]: {topicId: number, topic: string, section: string}[]}>;

  constructor(private osceSrv: OsceService,
              private router: Router,
              private scroller: PageScrollService,
              private route: ActivatedRoute,
              @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
    this.topicsByGroup$ = this.osceSrv.getTopicsGroupedBySection();
  }

  public gotoTopic(topic) {
    if ('text' === topic.view) {
      this.scroller.scroll({
        document: this.document,
        scrollTarget: '#topicBox',
        duration: 1200,
        easingLogic: quintEasing
      });

      setTimeout(() => {
        this.router.navigate([
          'topics',
          renderEncodedUri(topic.section),
          topic.topicId,
          renderEncodedUri(topic.topic)
        ], {relativeTo: this.route})
      }, 1000);
    } else if ('deck' === topic.view && null !== topic.deckId) {
      this.router.navigate([
        'osce-slide-decks',
        topic.deckId
      ])
    }
  }

  public noSort = (a, b) => a.key;

}
