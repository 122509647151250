import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FeedbackService } from './feedback.service';
import { getRequestHelper } from '../_helpers';
import { OsceTopic, Slide } from '../_models';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OsceService {
  constructor(private http: HttpClient,
              private feedbackSrv: FeedbackService,
              private router: Router,
              private authSrv: AuthService) {
  }

  /**
   * Get listing of OSCE topics grouped by section
   */
  public getTopicsGroupedBySection(): Observable<{[section: string]: {topicId: number, topic: string, view: string, deckId: number, section: string}[]}> {
    const { url } = getRequestHelper('/osce/topics-and-sections');

    return this.http.get<{[section: string]: {topicId: number, topic: string, view: string, deckId: number, section: string}[]}>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get OSCE topic by topicID
   */
  public getTopicById(id: number): Observable<OsceTopic> {
    const { url } = getRequestHelper(`/osce/topics/${id}`);

    return this.http.get<OsceTopic>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * Get slides associated with deck by id
   *
   * @param deckId
   * @returns Slide[]
   */
  public getSlidesByDeckId(deckId: string|number): Observable<Slide[]> {
    const { url } = getRequestHelper(`/osce/slide-decks/${deckId}/slides`);
    return this.http.get<Slide[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  /**
   * helper error handler
   */
  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      this.router.navigateByUrl('/products');
    } else if (error.status === 401) {
      this.authSrv.logout();
      this.router.navigateByUrl('/login');
    } else {
      this.feedbackSrv.setFeedback('error');
    }

    console.log('[UBP Error] ' + error.message);
    return throwError(error);
  }
}
