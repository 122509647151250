import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { postRequestHelper } from '../_helpers';


@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private http: HttpClient) {
  }

  /**
   * Get OSCE topic by topicID
   */
  public unsubscribe({emailAddress, emailId, signature}) {
    const { url, body, headers } = postRequestHelper({ path: '/email-marketing/unsubscribe', body: { emailAddress, emailId, signature } });
    return this.http.post(url, body, {headers: headers});
  }
}
