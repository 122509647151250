import { Component, OnInit } from '@angular/core';
import { WorkbookService } from 'src/app/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'primer-listing',
  templateUrl: './sidebar-pullout-primer-listing.component.pug',
  styleUrls: ['./sidebar-pullout-primer-listing.component.scss']
})
export class SidebarPulloutPrimerListingComponent implements OnInit {
  public examListing$;

  constructor(private workbookSrv: WorkbookService,
              private router: Router) { }

  ngOnInit() {
    this.examListing$ = this.workbookSrv.getPrimerExamListing();
  }

  public gotoExam(id: number, type: string) {
    switch (type) {
      case 'mock-exam':
        this.router.navigate(['workbook', type, id, 'short-form']);
        break;
      case 'additional-topic':
        this.router.navigate(['workbook', type, id, 'at-introduction']);
        break;
    }
  }
}
