import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AuthService, AccountService } from '../../_services';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {
  getStates,
  getTimeZones,
  getClassDesignations,
  mustMatchValidator,
  RECAPTCHA_SITE_KEY } from '../../_helpers';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import quintEasing from "../../_helpers/helpers-easing";

@Component({
  selector: 'account-create',
  templateUrl: './account-create.component.pug',
  styleUrls: ['./account-create.component.scss']
})
export class AccountCreateComponent implements OnInit {
  public registrationForm: FormGroup;
  public readonly states: string[];
  public readonly timezones: string[];
  public readonly classDesignations: string[];
  public errors;
  public siteKey: string;
  public showErrors: boolean = false;
  public showSuccessMessage: boolean = false;
  public creatingAccount: boolean = false;
  @ViewChild('recaptcha', {static: false}) recaptchaComponent;

  constructor(private authSrv: AuthService,
              private router: Router,
              private accountSrv: AccountService,
              private scroller: PageScrollService,
              @Inject(DOCUMENT) private document: any,) {
    if (this.authSrv.tokenIsCurrent()) {
      this.router.navigate(['/']);
    } else {
      this.states            = getStates();
      this.timezones         = getTimeZones();
      this.classDesignations = getClassDesignations();
      this.siteKey           = RECAPTCHA_SITE_KEY;
    }
  }

  ngOnInit() {
    this.registrationForm = new FormGroup({
      "firstName":          new FormControl(null, Validators.required),
      "lastName":           new FormControl(null, Validators.required),
      "email":              new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),
      "phoneNumber":        new FormControl(null, [
        Validators.required,
        Validators.pattern('^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$')
      ]),
      "address1":           new FormControl(null, Validators.required),
      "address2":           new FormControl(null),
      "city":               new FormControl(null, Validators.required),
      "state":              new FormControl(null, Validators.required),
      "zipCode":            new FormControl(null, [
        Validators.required,
        Validators.pattern('[0-9]{5}')
      ]),
      "timezone":           new FormControl(null, Validators.required),
      "classDesignation":   new FormControl(null),
      "password":           new FormControl(null, [
        Validators.required,
        Validators.minLength(12),
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*()_+=?])[-A-Za-z0-9!@#$%^&*()_+=?]{12,}$")
      ]),
      "matchPassword":      new FormControl(null, Validators.required),
      "recaptcha":          new FormControl(null, Validators.required),
    }, mustMatchValidator('password', 'matchPassword'));
  }

  public resolveRecaptcha(captchaResponse: string) {
    this.registrationForm.get('recaptcha').setValue(captchaResponse);
  }

  public onSubmit() {
    this.creatingAccount = true;

    this.accountSrv.createAccount(this.registrationForm.value).subscribe(
      res => {
        this.creatingAccount = false;
        this.showErrors = false;
        this.showSuccessMessage = true;

        this.recaptchaComponent.reset();
        this.registrationForm.reset();

        this.scroller.scroll({
          document: this.document,
          scrollTarget: '#success',
          duration: 700,
          easingLogic: quintEasing
        });
      },
      err => {
        this.recaptchaComponent.reset();
        this.creatingAccount = false;

        if (400 === err.status) {
          this.router.navigate(['/']);
        } else if (422 === err.status) {
          this.errors = err.error;
          this.showErrors = true;

          this.scroller.scroll({
            document: this.document,
            scrollTarget: '#errors',
            duration: 700,
            easingLogic: quintEasing,
            scrollOffset: 25
          });
        }
      }
    );
  }
}
