import { Component, OnInit, Inject } from '@angular/core';
import { DocumentService } from '../../_services/document.service';
import { Observable, throwError } from 'rxjs';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import quintEasing from "../../_helpers/helpers-easing";

import { OnlineDocument } from '../../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'document',
  templateUrl: './document.component.pug',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit {
  public document$: Observable<OnlineDocument>
  public documentId: string|number;

  constructor(private documentSrv: DocumentService,
              private route: ActivatedRoute,
              private scroller: PageScrollService,
              private router: Router,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    let documentId = this.route.snapshot.params['id'];
    this.documentId  = /^\d+$/.test(documentId) ? +documentId : documentId;
    this.document$   = this.documentSrv.getFullDocument(this.documentId).pipe(
      catchError((err: HttpErrorResponse) => {
        if (403 === err.status) {
          this.router.navigateByUrl('/products');
        }

        return throwError(err);
      })
    );
  }

  public gotoTop() {
    this.scroller.scroll({
      document: this.document,
      scrollTarget: '#top',
      duration: 700,
      easingLogic: quintEasing
    });
  }
}
