import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RoleGuard } from '../_guards';

import { OnlineExamListingComponent } from './online-exam-listing/online-exam-listing.component';

const ONLINE_EXAM_ROLES: string[] = [
    'ROLE_PRIVATE_ONLINE',
    'ROLE_GOLD',
    'ROLE_PLATINUM',
    'ROLE_LEGACY',
    'ROLE_ULTIMATE',
    'ROLE_ADMIN',
    'ROLE_SUPER_ADMIN',
];

const ROUTES_ONLINE_EXAM: Routes = [
    {
        path: 'private-online-exams',
        component: OnlineExamListingComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {expectedRoles: ONLINE_EXAM_ROLES}
    }
];

@NgModule({
    imports: [RouterModule.forChild(ROUTES_ONLINE_EXAM)],
    exports: [RouterModule]
})
export class OnlineExamRouting { }
