import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';

import { postRequestHelper, getRefreshToken, ADMIN_ROLES } from '../_helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isAdmin: BehaviorSubject<boolean>           = new BehaviorSubject(false);
  private _isExaminer: BehaviorSubject<boolean>        = new BehaviorSubject(false);
  private _isLoggedIn: BehaviorSubject<boolean>        = new BehaviorSubject(false);
  private _isExpiringSoon: BehaviorSubject<boolean>    = new BehaviorSubject(false);
  private _isActiveApplied: BehaviorSubject<boolean>   = new BehaviorSubject(false);
  private _acctExpDate: BehaviorSubject<string>        = new BehaviorSubject('');
  private _expType: BehaviorSubject<string>            = new BehaviorSubject('');

  public readonly isAdmin: Observable<boolean>         = this._isAdmin.asObservable();
  public readonly isExaminer: Observable<boolean>      = this._isExaminer.asObservable();
  public readonly isLoggedIn: Observable<boolean>      = this._isLoggedIn.asObservable();
  public readonly isExpiringSoon: Observable<boolean>  = this._isExpiringSoon.asObservable();
  public readonly isActiveApplied: Observable<boolean> = this._isActiveApplied.asObservable();
  public readonly acctExpDate: Observable<string>      = this._acctExpDate.asObservable();
  public readonly expType: Observable<string>          = this._expType.asObservable();

  constructor(private http: HttpClient,
              private router: Router,
              private jwt: JwtHelperService) { }

  // is this person an admin?
  public setIsAdmin(token) {
    this._isAdmin.next(false);
    const roles = (this.jwt.decodeToken(token)).roles;

    roles.forEach(ele => {
      if (ADMIN_ROLES.includes(ele)) this._isAdmin.next(true);
    });
  }

  // is this person an examiner?
  public setIsExaminer(token) {
    this._isExaminer.next(false);
    const roles = (this.jwt.decodeToken(token)).roles;

    roles.forEach(ele => {
      if ('ROLE_EXAMINER' === ele) this._isExaminer.next(true);
    });
  }

  // is this person an examiner?
  public setIsActiveApplied(token) {
    this._isActiveApplied.next(false);
    const roles = (this.jwt.decodeToken(token)).roles;

    roles.forEach(ele => {
      if ('ROLE_ACTIVE_APPLIED_PRODUCT_USER' === ele) this._isActiveApplied.next(true);
    });
  }

  // is this person an admin?
  public setIsLoggedIn(loggedIn: boolean) {
    this._isLoggedIn.next(loggedIn);
  }

  // is this person expiring soon?
  public setIsExpiringSoon(token) {
    const expiring = (this.jwt.decodeToken(token)).expiringSoon;
    this._isExpiringSoon.next(expiring);

    /** if the user is actually expiring, get the date */
    if (expiring) {
      const expDate = (this.jwt.decodeToken(token)).accountExpiration;
      this._acctExpDate.next(expDate);
    } else {
      this._acctExpDate.next('');
    }
  }

  // How is this person expiring? Normally or ABA verification
  public setExpirationType(token) {
    this._expType.next((this.jwt.decodeToken(token)).expirationType);
  }

  /**
   * Basic login request
   */
  public login(credentials) {
    // build the request parameters
    const { url, body, headers } = postRequestHelper({
      path: '/login_check',
      body: credentials
    });

    return this.http.post<{token: string, refresh_token: string}>(url, body, { headers: headers })
    .pipe(
      catchError(this.handleError)
    );
  }

  /**
   * remove local storage, thereby making route guards fail
   * Once removed, navigate to login form.
   */
  public logout(gotoOnLogin = null): void {
    this._isAdmin.next(false);
    this._isLoggedIn.next(false);
    localStorage.clear();

    if (null !== gotoOnLogin) {
      this.router.navigate(['/login'], {queryParams: {'redirect': gotoOnLogin}});
    } else {
      this.router.navigate(['/login']);
    }
  }

  public reauthenticate(gotoOnLogin = null): Observable<boolean> {
    const refreshToken = getRefreshToken();

    if (null !== refreshToken) {
      return this.refreshToken(refreshToken).pipe(
        map(res => {
          localStorage.clear();

          this.setStorage(res.token, res.refresh_token);
          this.setIsLoggedIn(true);
          this.setIsAdmin(res.token);
          this.setIsExaminer(res.token);
          this.setIsActiveApplied(res.token);
          this.setIsExpiringSoon(res.token);
          this.setExpirationType(res.token);

          return true;
        }),
        catchError(err => {
          console.log('[AuthService] ' + err.message);
          this.logout(gotoOnLogin);
          return of(false);
        })
      );
    } else {
      this.logout(gotoOnLogin);
      return of(false);
    }
  }

  /**
   * Renew JWT if refresh_token is still current
   */
  public refreshToken(refreshToken: string): Observable<{token: string, refresh_token: string}> {
    // build the request parameters
    const { url, body, headers } = postRequestHelper({
      path: '/token/refresh',
      body: { refresh_token: refreshToken }
    });

    // return new tokens
    return this.http.post<{token: string, refresh_token: string}>(url, body, { headers: headers });
  }

  /**
   * utility to set local storage with tokens
   */
  public setStorage(token: string, refreshToken: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refreshToken);
  }

  /**
   * utility to check if token is expired. If not expired,
   * simple check assumes still authenticated
   */
  public tokenIsCurrent(): boolean {
    const token = localStorage.getItem('token');

    if (token && !this.jwt.isTokenExpired()) {
      this.setIsAdmin(token);
      this.setIsExaminer(token);
      this.setIsActiveApplied(token);
      this.setIsLoggedIn(true);
      this.setIsExpiringSoon(token);
      this.setExpirationType(token);

      return true;
    }

    return false;
  }

  /** Extra measure to indicate whether user can navigate
   * through application
   */
  public canNavigate(token = null): boolean {
    if (!token) {
      const token = localStorage.getItem('token');
    }

    const navStatus = (this.jwt.decodeToken(token)).navigation;

    return ('open' === navStatus);
  }

  /**
   * simple error handler
   */
  private handleError(error) {
    console.log('[AuthService] ' + error.message);
    return throwError(error);
  }
}
