import { Component, OnInit } from '@angular/core';
import { VideoService, DocumentService, AuthService } from '../_services';
import { Observable } from 'rxjs';
import { renderAvWindow } from '../_helpers';
import { OnlineDocument } from '../_models';

@Component({
  selector: 'applied-begin',
  templateUrl: './applied-begin.component.pug',
  styleUrls: ['./applied-begin.component.scss']
})
export class AppliedBeginComponent implements OnInit {
  public myStory$: Observable<{videoId: number, title: string}>;
  public documents$: Observable<OnlineDocument[]>;
  public isActiveApplied: boolean = false;

  constructor(private videoSrv: VideoService,
              private authSrv: AuthService,
              private documentSrv: DocumentService) { }

  ngOnInit() {
    this.authSrv.isActiveApplied.subscribe((isActiveApplied: boolean) => this.isActiveApplied = isActiveApplied);
    this.myStory$ = this.videoSrv.getByTag('dr-georges-story');
    this.documents$ = this.documentSrv.getByCategory('core-concepts');
  }

  public initAvWindow(type: string, src: any, directory: string = null) {
    renderAvWindow(type, src, directory);
  }
}
