import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { getRequestHelper } from "../_helpers";
import { SignedCookies, Slide } from "../_models";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class SlideService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authSrv: AuthService
  ) {}

  public getSignedCookies(slideId: number): Observable<SignedCookies> {
    const { url } = getRequestHelper(
      `/slide-decks/slides/${slideId}/sign-media-cookie`
    );
    return this.http.get<SignedCookies>(url).pipe(catchError(this.handleError));
  }

  public getDeckTitle(deckId: number): Observable<{ title: string }> {
    const { url } = getRequestHelper(`/slide-decks/${deckId}/title`);
    return this.http
      .get<{ title: string }>(url)
      .pipe(catchError(this.handleError));
  }

  /**
   * API will determine if deckId is a number ONLY or title,
   * thereby returning slides by appropriate search type
   * @param deckId
   * @returns Slide[]
   */
  public getSlidesByDeckIdentifier(deckId): Observable<Slide[]> {
    const { url } = getRequestHelper(`/slide-decks/${deckId}/slides`);
    return this.http.get<Slide[]>(url).pipe(catchError(this.handleError));
  }

  /**
   * helper error handler
   */
  private handleError = (error: HttpErrorResponse) => {
    if (error.status === 403) {
      this.router.navigateByUrl("/products");
    }

    if (error.status === 401) {
      this.authSrv.logout();
      this.router.navigateByUrl("/login");
    }

    console.log("[UBP Error] " + error.message);
    return throwError(error);
  };
}
