import { Component, OnInit, Input } from '@angular/core';
import { WorkbookService } from 'src/app/_services';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TaggedQuestionResult } from 'src/app/_models';

@Component({
  selector: 'tagged-question-listing',
  templateUrl: './sidebar-pullout-tagged-questions.component.pug',
  styleUrls: ['./sidebar-pullout-tagged-questions.component.scss']
})
export class SidebarPulloutTaggedQuestionsComponent implements OnInit {
  public taggedQuestions$: Observable<{[type: string]: TaggedQuestionResult[]}>;
  @Input() tagId: number;

  constructor(private workbookSrv: WorkbookService,
              private router: Router) { }

  ngOnInit() {
    this.taggedQuestions$ = this.workbookSrv.getExamQuestionsByTag(this.tagId);
  }

  public gotoOperativeManagement(managementType: string, meId: number, omId: number) {
    this.router.navigate(['/workbook/mock-exam', meId, 'operative-managements', managementType], {queryParams: {'highlight': omId}});
  }

  public gotoCaseScenario(atId: number, csId: number) {
    this.router.navigate(['/workbook/additional-topic', atId, 'case-scenarios'], {queryParams: {'highlight': csId}});
  }

  public gotoExam(id: number, type: string) {
    switch (type) {
      case 'mock-exam':
        this.router.navigate(['workbook', type, id, 'short-form']);
        break;
      case 'additional-topic':
        this.router.navigate(['workbook', type, id, 'at-introduction']);
        break;
    }
  }
}
