import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { WorkbookService } from '../../_services/workbook.service';
import { ExamBasicInformation } from '../../_models';

@Component({
  selector: 'workbook-toc',
  templateUrl: './workbook-toc.component.pug',
  styleUrls: ['./workbook-toc.component.scss']
})
export class WorkbookTocComponent implements OnInit {
  @Input() examId: number;
  @Input() examType: string;
  @Output() examInfo: EventEmitter<any> = new EventEmitter();

  constructor(private workbookSrv: WorkbookService) { }

  ngOnInit() {
    this.workbookSrv.getExamBasicInformation(+this.examId, this.examType).subscribe(
      (res: ExamBasicInformation) => {
        this.examInfo.emit(res);
      }
    );
  }
}
