import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { JwtModule } from "@auth0/angular-jwt";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbModalModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgxPageScrollCoreModule } from "ngx-page-scroll-core";
import { NgxPageScrollModule } from "ngx-page-scroll";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RecaptchaModule } from "ng-recaptcha";
import "@stripe/stripe-js";

import { AppRoutingModule } from "./app.routing";
import { AppComponent } from "./app.component";

import { getToken } from "./_helpers";

import { SharedModule } from "./_shared/shared.module";
import { WorkbookModule } from "./workbook/workbook.module";
import { EcommerceModule } from "./ecommerce/ecommerce.module";
import { OnlineExamModule } from "./online-exam/online-exam.module";

import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { OsceComponent } from "./osce/osce.component";
import { OsceTopicComponent } from "./osce/osce-topic/osce-topic.component";
import { AccountComponent } from "./account/account.component";
import { AppliedBeginComponent } from "./applied-begin/applied-begin.component";
import { CourseVideosComponent } from "./course-videos/course-videos.component";
import { AccountCreateComponent } from "./account/account-create/account-create.component";
import { AccountVerifyComponent } from "./account/account-verify/account-verify.component";
import { AccountExpireComponent } from "./account/account-expire/account-expire.component";
import { ResetPasswordComponent } from "./login/reset-password/reset-password.component";
import { AccountProductsComponent } from "./account/account-products/account-products.component";
import { ManualComponent } from "./manual/manual.component";
import { ManualIndexComponent } from "./manual/manual-index/manual-index.component";
import { ManualEntryComponent } from "./manual/manual-entry/manual-entry.component";
import { FreeMaterialComponent } from "./free-material/free-material.component";
import { OsceSlideDeckComponent } from "./osce/osce-slide-deck/osce-slide-deck.component";
import { MediaSlideDeckComponent } from "./media-slide-deck/media-slide-deck.component";
import { SmallGroupComponent } from "./small-group/small-group/small-group.component";
import { EmailUnsubscribeComponent } from './email/unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    OsceComponent,
    OsceTopicComponent,
    AccountComponent,
    AppliedBeginComponent,
    CourseVideosComponent,
    AccountCreateComponent,
    AccountVerifyComponent,
    ResetPasswordComponent,
    AccountProductsComponent,
    ManualComponent,
    ManualIndexComponent,
    ManualEntryComponent,
    FreeMaterialComponent,
    OsceSlideDeckComponent,
    AccountExpireComponent,
    MediaSlideDeckComponent,
    SmallGroupComponent,
    EmailUnsubscribeComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    RecaptchaModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: [
          "127.0.0.1:8000",
          "localhost:8000",
          "api.ultimateboardprep.com",
          "api.stage.ultimateboardprep.com",
          "api.ultimateboardprep.local"
        ],
      },
    }),
    WorkbookModule,
    SharedModule,
    EcommerceModule,
    OnlineExamModule,
    AppRoutingModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
