import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Router } from '@angular/router';
import flatpickr from "flatpickr";
import "flatpickr/dist/themes/dark.css";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BlockEvent, ExaminerBasicInformation, OnlineExamListing, ReservedExamListing } from 'src/app/_models';
import { OnlineExamService } from '../../_services';
import { BlockListingComponent } from '../block-listing/block-listing.component';

@Component({
  selector: 'online-exam-listing',
  templateUrl: './online-exam-listing.component.pug',
  styleUrls: ['./online-exam-listing.component.scss']
})
export class OnlineExamListingComponent implements OnInit, OnDestroy {
  public datePicker;
  public fetchingExams: boolean = false;
  public exams: OnlineExamListing[];
  public reservedExams$: Observable<ReservedExamListing>;
  public examiners$: Observable<ExaminerBasicInformation[]> = null;
  public currentExaminerSearch: ExaminerBasicInformation = null;
  public reservedExamsError = null;
  public examinerError = null;
  public cancelErrorMessage: string = '';
  public showSearchNoResults: boolean = false;
  public showExaminersNoResults: boolean = false;
  public showExaminerSearchButton: boolean = true;

  @ViewChild('examDatePicker', { static: true }) examDatePicker: ElementRef;
  @ViewChild('searchDateText', { static: true }) searchDateText: ElementRef;
  @ViewChildren('blockListing') blockListings: QueryList<BlockListingComponent>;
  @ViewChildren('cancelErrMsg', { read: ElementRef }) cancelErrMsgs: QueryList<ElementRef>;

  constructor(private examSrv: OnlineExamService,
              private router: Router) {}

  ngOnInit() {
    this.getReservedExams();
    this.datePicker = flatpickr(this.examDatePicker.nativeElement, {
      dateFormat: "F j",
      mode: "range",
      minDate: "today",
      onClose: this.getDates.bind(this)
    });
  }

  public getDates(dates) {
    this.examiners$ = null;
    let qStr = '';
    let chosenDates = [];
    this.showSearchNoResults = false;

    dates.forEach(element => {
      chosenDates.push(element.toISOString().slice(0, 10));
    });

    // compose the query string to API
    if (chosenDates.length) {
      if (chosenDates.length > 1) {
        qStr = encodeURI(`start=${chosenDates[0]}&end=${chosenDates[1]}`);
      } else {
        qStr = encodeURI(`start=${chosenDates[0]}`);
      }
    }

    // only run the service if dates have been chosen
    // various UI interactions trigger an "onClose" event
    if (qStr) {
      this.fetchingExams = true;
      this.examSrv.searchExamsByDateRange(qStr)
        .subscribe(
          (res: OnlineExamListing[]) => {
            this.fetchingExams = false;

            if (this.currentExaminerSearch) {
              this.exams = this.filterExamsByExaminerSearch(res);
            } else {
              this.exams = res;
            }
          },
          (err: HttpErrorResponse) => {
            this.fetchingExams = false;

            if (404 === err.status) {
              this.exams = null;
              this.showSearchNoResults = true;
            }
          }
        );
    }
  }

  public clearCalendar() {
    this.showSearchNoResults = false;
    this.datePicker.clear();
    this.exams = null;
  }

  public reserveExam(id) {
    let feedbackProcessing = document.getElementById('reservation-processing-' + id);
    let feedbackSuccess    = document.getElementById('reservation-success-' + id);
    let feedbackTickets    = document.getElementById('need-tickets-' + id);
    let feedbackError      = document.getElementById('system-error-' + id);

    feedbackProcessing.classList.remove('d-none');

    this.examSrv.reserveExam(id).subscribe(
      res => {
        feedbackProcessing.classList.add('d-none');
        feedbackSuccess.classList.remove('d-none');

        setTimeout(() => {
          this.clearCalendar();
          this.getReservedExams();
        }, 3000);
      },
      (err: HttpErrorResponse) => {
        switch (err.status) {
          case 403:
            feedbackTickets.classList.remove('d-none');
            setTimeout(() => this.router.navigate(['products']), 3000);
            break;
          case 400:
          case 404:
          case 500:
            feedbackError.classList.remove('d-none');
            break;
          default:
            feedbackError.classList.remove('d-none');
        }

        feedbackProcessing.classList.add('d-none');
      }
    );
  }

  public cancelExam(id, type, index) {
    let feedbackProcessingCancel = document.getElementById('cancel-spinner-' + id);
    let cancelSuccessMsg = document.getElementById('cancel-success-message-' + id)
    let cancelErrorMsg = document.getElementById('cancel-error-message-' + id)

    cancelSuccessMsg.classList.add('d-none');
    cancelErrorMsg.classList.add('d-none');
    feedbackProcessingCancel.classList.remove('d-none');

    if ('block' === type) {
      this.examSrv.cancelBlockExam(id).subscribe(
        res => {
          feedbackProcessingCancel.classList.add('d-none');
          cancelSuccessMsg.classList.remove('d-none');
          setTimeout(() => this.getReservedExams(), 3000);

        },
        (err: HttpErrorResponse) => {
          feedbackProcessingCancel.classList.add('d-none');
          this.getResponseErrorMsg(err, index);
          cancelErrorMsg.classList.remove('d-none');
        }
      );
    } else {
      this.examSrv.cancelExam(id).subscribe(
        res => {
          feedbackProcessingCancel.classList.add('d-none');
          cancelSuccessMsg.classList.remove('d-none');
          setTimeout(() => this.getReservedExams(), 3000);

        },
        (err: HttpErrorResponse) => {
          feedbackProcessingCancel.classList.add('d-none');
          this.getResponseErrorMsg(err, index);
          cancelErrorMsg.classList.remove('d-none');
        }
      );
    }
  }

  public getBlocks(id, index) {
    const fbSpinner = document.getElementById('reservation-processing-' + id);
    fbSpinner.classList.toggle('d-none');


    this.examSrv.getAvailableBlocks(id).subscribe(
      (res: BlockEvent) => {
        const block = this.blockListings.toArray()[index];
        fbSpinner.classList.toggle('d-none');

        if (res) {
          block.showEmpty = false;
          block.blockData = res;
        } else {
          block.showEmpty = true;
          block.blockData = null;
        }
      }
    );
  }

  public getExaminers() {
    this.clearCalendar();
    this.examiners$ = null;
    this.showExaminersNoResults = false;
    this.showSearchNoResults = false;

    this.examiners$ = this.examSrv.getExaminers().pipe(
      catchError((err: HttpErrorResponse) => {
        if (404 === err.status) {
          this.showExaminersNoResults = true;
        } else {
          this.examinerError = err;
        }

        return throwError(err);
      })
    );
  }

  public resetExaminer() {
    this.searchDateText.nativeElement.innerText = 'search by date'
    this.currentExaminerSearch = null;
    this.showExaminerSearchButton = true;
    this.clearCalendar();
  }

  public setExaminer(examiner) {
    this.examiners$ = null;
    this.currentExaminerSearch = examiner;
    this.showExaminerSearchButton = false;
    this.searchDateText.nativeElement.innerText = 'search examiner dates'
  }

  public blockExamReservation(e) {
    if (e) {
      setTimeout(() => {
        this.clearCalendar();
        this.getReservedExams();
      }, 3000);
    }
  }

  private getResponseErrorMsg(err, childIndex) {
    switch (err.status) {
      case 404:
        (this.cancelErrMsgs.toArray()[childIndex]).nativeElement.innerText = 'Could not find exam to cancel. Please refresh the page.';
        break;
      case 400:
        (this.cancelErrMsgs.toArray()[childIndex]).nativeElement.innerText = 'Exams can only be canceled if the timeframe is greater than 24hrs (taking into account timezones).';
        break;
      case 403:
        (this.cancelErrMsgs.toArray()[childIndex]).nativeElement.innerText = 'You do not possess the correct priviledges to cancel exams.';
        break;
      case 409:
      case 500:
        (this.cancelErrMsgs.toArray()[childIndex]).nativeElement.innerText = 'An error has occured. Please try again later.';
        break;
    }
  }

  private filterExamsByExaminerSearch(exams) {
    const filtered = [];

    exams.forEach(e => {
      if (this.currentExaminerSearch.id === e.examiner.id) {
        filtered.push(e);
      }
    });

    if (filtered.length) {
      return filtered;
    } else {
      this.exams = null;
      this.showSearchNoResults = true;
    }
  }

  private getReservedExams() {
    this.reservedExams$ = null;

    this.reservedExams$ = this.examSrv.getReservedExams().pipe(
      catchError((err: HttpErrorResponse) => {
        this.reservedExamsError = err;
        return throwError(err);
      })
    );
  }

  ngOnDestroy() {
    this.datePicker.destroy();
  }
}
