import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "split" })
export class SplitPipe implements PipeTransform {
  constructor() {}

  transform(val: string, splitOn: string): any {
    return val.split(splitOn);
  }
}
