import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mustMatchValidator } from 'src/app/_helpers';
import { AccountService } from 'src/app/_services';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.pug',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public submitUsernameForm: FormGroup;
  public submitPasswordForm: FormGroup;
  public token: string;
  public errors;
  public requestProcessing: boolean = false;
  public errorMessage: string;
  public successMessage: string;
  public showError: boolean = false;
  public showPasswordRequirementErrors: boolean = false;
  public showSuccess: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountSrv: AccountService) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('t');

    if (this.token) {
      this.submitPasswordForm = new FormGroup({
        "password":           new FormControl(null, [
          Validators.required,
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-!@#$%^&*()_+=?])[-A-Za-z0-9!@#$%^&*()_+=?]{12,}$")
        ]),
        "matchPassword":      new FormControl(null),
        "token":              new FormControl(null)
      }, mustMatchValidator('password', 'matchPassword'));
    } else {
      this.submitUsernameForm = new FormGroup({
        'email': new FormControl(null, [
          Validators.required,
          Validators.email,
        ])
      });
    }
  }

  public onUsernameSubmit() {
    this.requestProcessing = true;

    this.accountSrv.requestPasswordReset(this.submitUsernameForm.value).subscribe(
      res => {
        this.requestProcessing = false;

        this.successMessage = 'Password reset requested successfully. Check your email for a message from reset-password@account.ultimateboardprep.com.'
        this.showError = false;
        this.showSuccess = true;

        setTimeout(() => {
          this.router.navigateByUrl('/login')
        }, 10000);
      },
      err => {
        this.requestProcessing = false;

        if (404 === err.status) {
          this.errorMessage = "Problem encountered. Please check that the spelling is correct."
        } else if (400 === err.status) {
          this.errorMessage = "Problem encountered. Reset possibly already requested. If you need to request a password reset, please wait at least 15 minutes. If you cannot locate the reset email that was sent, make sure to check both your 'Junk' and 'SPAM' folders."
        } else {
          this.errorMessage = "Problem encountered. Please contact information@ultimateboardprep.com or call us at (316) 776-9898."
        }

        this.showError = true;
        this.showSuccess = false;
      }
    )
  }

  public onPasswordSubmit() {
    this.requestProcessing = true;
    this.showPasswordRequirementErrors = false;

    this.submitPasswordForm.get('token').setValue(this.token);

    this.accountSrv.resetPasswordLoggedOut(this.submitPasswordForm.value).subscribe(
      res => {
        this.requestProcessing = false;
        this.successMessage = 'Password successfully reset'
        this.showError = false;
        this.showSuccess = true;
      },
      err => {
        this.requestProcessing = false;

        switch (err.status) {
          case 400:
            this.router.navigateByUrl('/login');
            break;

          case 404:
            this.errorMessage = 'Error: Perhaps you already reset your password? If this is the case, simply login with the password you created or try the reset process again.';
            break;

          case 403:
            this.errorMessage = 'Error: Cannot reset password. Perhaps the link you used is expired. Try the reset process again.';
            break;

          case 422:
            this.errorMessage = 'Password did not meet requirements.';
            this.errors = err.error;
            this.showPasswordRequirementErrors = true;
            break;

          default:
            this.errorMessage = 'An error has occured';
            break;
        }

        this.showSuccess = false;
        this.showError = true;
      }
    );
  }

}
