import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../../_services/account.service';

@Component({
  selector: 'account-verify',
  templateUrl: './account-verify.component.pug',
  styleUrls: ['./account-verify.component.scss']
})
export class AccountVerifyComponent implements OnInit {
  public viewSuccess: boolean = false;
  public viewError: boolean = false;
  public viewLoading: boolean = true;

  constructor(private route: ActivatedRoute,
              private accountSrv: AccountService) { }

  ngOnInit() {
    const token = this.route.snapshot.queryParamMap.get('t');

    if (token) {
      this.accountSrv.verifyAccount(token).subscribe(
        res => {
          this.viewLoading = false;
          this.viewSuccess = true;
        },
        err => {
          this.viewLoading = false;
          this.viewError = true;
        }
      );
    } else {
      this.viewError = true;
    }
  }
}
