import { Component, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Observable, throwError } from 'rxjs';
import { ShoppingCart } from 'src/app/_models';
import { EcommerceService } from 'src/app/_services';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { noSort } from 'src/app/_helpers';

@Component({
  selector: 'shopping-cart',
  templateUrl: './cart.component.pug',
  styleUrls: ['./cart.component.scss'],
  animations: [
    trigger('cart', [
      state('show', style({
        right: '0',
        boxShadow: '-5px 0 10px rgba(0, 0, 0, .5)'
      })),
      state('hide', style({
        left: '100%',
        boxShadow: '-5px 0 10px rgba(0, 0, 0, 0)'
      })),
      transition('show <=> hide', animate(".3s ease-in-out")),
    ])
  ]
})
export class CartComponent implements OnInit {
  public cart$: Observable<ShoppingCart>;
  public cartError: string;
  public cart404: string;
  public cartMessage$: Observable<string>;
  public cartAlertType$: Observable<string>;
  public cVisibility: string = 'hide';
  public processingRemove: boolean = false;
  public noSort = noSort;

  constructor(private eCommSrv: EcommerceService,
              private router: Router) { }

  ngOnInit() {
    this.cartAlertType$ = this.eCommSrv.cartAlertType;
    this.cartMessage$ = this.eCommSrv.cartMessage;
    this.eCommSrv.cartUpdated.subscribe(
      updated => {
        if (updated) {
          this.cVisibility = 'show';
          this.eCommSrv.utilSetCartUpdated(false);
          this.eCommSrv.utilSetLockCartActions(false);

          // get the current unpurchased cart
          this.cart$ = this.eCommSrv.getCart().pipe(
            tap(() => {
              this.cart404 = undefined;
              this.cartError = undefined;
              this.processingRemove = false;
            }),
            catchError((err: HttpErrorResponse) => {
              if (404 === err.status) {
                this.cart404 = 'You do not currently have a shopping cart.';
              } else {
                this.cartError = 'There was an error in retrieving your cart';
              }

              console.error(err);
              return throwError(err);
            })
          );
        }
      }
    );
  }

  public gotoCheckout() {
    this.eCommSrv.utilSetCartMessage(null);
    this.router.navigate(['checkout']);
  }

  public closeCart() {
    this.cVisibility = 'hide';
    this.eCommSrv.utilSetCartMessage(null);
  }

  public removeCartItem(id) {
    this.processingRemove = true;
    this.eCommSrv.removeFromCart(id).subscribe(
      res => {
        this.eCommSrv.utilSetCartAlertType('alert-warning');
        this.eCommSrv.utilSetCartMessage('item successfully deleted');
        this.eCommSrv.utilSetCartUpdated(true);
      },
      (err: HttpErrorResponse) => {
        this.eCommSrv.utilSetCartAlertType('alert-danger');

        switch (err.status) {
          case 499:
            this.eCommSrv.utilSetCartMessage('Your cart is locked.');
            break;
          default:
            this.eCommSrv.utilSetCartMessage('Unknown error. Please contact customer support.');
            break;
        }

        this.eCommSrv.utilSetCartUpdated(true);
      }
    );
  }

  public deleteCart() {
    this.processingRemove = true;
    this.eCommSrv.deleteCart().subscribe(
      res => {
        this.eCommSrv.utilSetCartAlertType('alert-info');
        this.eCommSrv.utilSetCartMessage('cart successfully deleted');
        this.eCommSrv.utilSetCartUpdated(true);
      },
      err => {
        this.eCommSrv.utilSetCartAlertType('alert-danger');
        this.eCommSrv.utilSetCartMessage('Unknown error. Please contact customer support.');
        this.eCommSrv.utilSetCartUpdated(true);
      }
    );
  }

}
