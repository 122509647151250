import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getRequestHelper, postRequestHelper } from '../_helpers';
import { PurchaseReceipt, ShoppingCart, EcommerceItem } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class EcommerceService {
  private _cartActionsLocked: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly cartActionsLocked: Observable<boolean> = this._cartActionsLocked.asObservable();

  public _cartMessage: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly cartMessage: Observable<string> = this._cartMessage.asObservable();

  public _cartAlertType: BehaviorSubject<string> = new BehaviorSubject('');
  public readonly cartAlertType: Observable<string> = this._cartAlertType.asObservable();

  public _cartUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public readonly cartUpdated: Observable<boolean> = this._cartUpdated.asObservable();

  constructor(private http: HttpClient) { }

  public getEcommerceItems(): Observable<EcommerceItem[]> {
    const { url } = getRequestHelper(`/shop/ecommerce-items-total`);
    return this.http.get<EcommerceItem[]>(url);
  }

  public getCart(): Observable<ShoppingCart> {
    const { url } = getRequestHelper(`/shop/cart`);
    return this.http.get<ShoppingCart>(url).pipe(
      catchError(this.handleError)
    );
  }

  public addToCart(uuid, quantity) {
    const { url, body, headers } = postRequestHelper({path: `/shop/cart/item/${uuid}/${quantity}`, body: null});
    return this.http.post(url, body, {headers: headers});
  }

  public addSubscriptionToCart(uuid, interval) {
    const { url, body, headers } = postRequestHelper({path: `/shop/cart/item/${uuid}/subscription-interval/${interval}`, body: null});
    return this.http.post(url, body, {headers: headers});
  }

  public removeFromCart(id) {
    const { url } = getRequestHelper(`/shop/cart/item/${id}`);
    return this.http.delete(url);
  }

  public lockCart() {
    const { url, body, headers } = postRequestHelper({path: `/shop/cart/lock`, body: null});
    return this.http.post(url, body, {headers: headers});
  }

  public deleteCart() {
    const { url } = getRequestHelper(`/shop/cart`);
    return this.http.delete(url);
  }

  public initStripe(): Observable<{'clientSecret': string}> {
    const { url } = getRequestHelper(`/shop/initiate-stripe`);
    return this.http.get<{'clientSecret': string}>(url).pipe(
      catchError(this.handleError)
    );
  }

  public confirmStripe(paymentIntentId: string): Observable<PurchaseReceipt> {
    const { url } = getRequestHelper(`/shop/confirm-stripe/${paymentIntentId}`);
    return this.http.get<PurchaseReceipt>(url);
  }

  /************************************************* UTIL */
  /****************************************************** */

  private handleError = (error: HttpErrorResponse) => {
    console.log('[UBP Error] ' + error.message);
    return throwError(error);
  }

  public utilSetLockCartActions(locked: boolean) {
    this._cartActionsLocked.next(locked);
  }

  public utilSetCartMessage(message: string) {
    this._cartMessage.next(message);
  }

  public utilSetCartAlertType(type: string) {
    this._cartAlertType.next(type);
  }

  public utilSetCartUpdated(updated: boolean) {
    this._cartUpdated.next(updated);
  }
}
