import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { FeedbackService } from './feedback.service';
import { AuthService } from './auth.service';
import { Account, AccountManage, AccountOwnedProducts, ShippingAddress } from '../_models';
import { postRequestHelper, getRequestHelper } from '../_helpers';


@Injectable({providedIn: 'root'})
export class AccountService {
  constructor(private http: HttpClient,
              private feedbackSrv: FeedbackService,
              private router: Router,
              private authSrv: AuthService) { }

/**
 * Request to create student account
 * @param account Account
 */
  public createAccount(account: Account) {
    const { url, body, headers } = postRequestHelper({path: '/user/create', body: account});

    return this.http.post(url, body, {headers: headers});
  }

  /**
   * Verify email address with token query parameter
   * @param token string
   */
  public verifyAccount(token: string) {
    const { url } = getRequestHelper(`/user/create/verify/${token}`);

    return this.http.get(url);
  }

  public getCoinCount(): Observable<{coinCount: number}> {
    const { url } = getRequestHelper(`/user/get-coin-count`);
    return this.http.get<{coinCount: number}>(url);
  }

  public getOwnedProducts(): Observable<AccountOwnedProducts> {
    const { url } = getRequestHelper(`/user/products`);
    return this.http.get<AccountOwnedProducts>(url);
  }

  /**
   * Get editable fields for current user account
   */
  public getAccount():Observable<AccountManage> {
    const { url } = getRequestHelper(`/user/manage/account`);
    return this.http.get<AccountManage>(url);
  }

  /**
   * Update editable fields for current user account
   */
  public updateAccount(account: AccountManage) {
    const { url, body, headers } = postRequestHelper({path: `/user/manage/account`, body: account});
    return this.http.put(url, body, {headers: headers});
  }

  /**
   * Reset a logged in user's password
   * @param passwordValue string
   */
  public resetPasswordLoggedIn(passwordValue) {
    const { url, body, headers } = postRequestHelper({path: `/user/manage/password`, body: passwordValue});
    return this.http.put(url, body, {headers: headers});
  }

  /**
   * Request a password reset for a logged out user
   * @param passwordValue string
   */
  public requestPasswordReset(passwordValue) {
    const { url, body, headers } = postRequestHelper({path: `/user/password-reset`, body: passwordValue});
    return this.http.post(url, body, {headers: headers});
  }

  /**
   * Reset a logged out user's password
   * @param passwordWithToken string
   */
  public resetPasswordLoggedOut(passwordWithToken) {
    const { url, body, headers } = postRequestHelper({path: `/user/password-reset`, body: passwordWithToken});
    return this.http.put(url, body, {headers: headers});
  }

  public getShippingAdress(): Observable<ShippingAddress> {
    const { url } = getRequestHelper(`/user/shipping-address`);
    return this.http.get<ShippingAddress>(url);
  }

  public updateShippingAddress(shippingDetails): Observable<ShippingAddress> {
    const { url, body, headers } = postRequestHelper({path: `/user/shipping-address`, body: shippingDetails});
    return this.http.post<ShippingAddress>(url, body, {headers: headers});
  }
}
