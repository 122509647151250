import { Component, Inject } from '@angular/core';
import { WorkbookService, FeedbackService, AuthService } from '../../_services';
import { ActivatedRoute } from '@angular/router';
import { PageScrollService, EasingLogic } from 'ngx-page-scroll-core';
import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Subscription} from 'rxjs';
import { ExamSubPart } from '../../_models';

@Component({
  selector: 'workbook-exam-subpart',
  templateUrl: './workbook-exam-subpart.component.pug',
  styleUrls: ['./workbook-exam-subpart.component.scss']
})
export class WorkbookExamSubpartComponent {
  public managementType: string = null;
  public subPartType: string = null;
  public listings: { id: number; question: string; }[] = null;
  public fullPart = null;
  public feedback: string;
  public questionPrevious: number;
  public questionNext: number;

  protected feedbackSubscription: Subscription;

  constructor(protected workbookSrv: WorkbookService,
              protected route: ActivatedRoute,
              protected feedbackSrv: FeedbackService,
              protected scroller: PageScrollService,
              protected authSrv: AuthService,
              @Inject(DOCUMENT) protected document: any,
              protected viewportScroller: ViewportScroller) {
    this.feedbackSubscription = this.feedbackSrv.feedback.subscribe(feedback => this.feedback = feedback);
    this.feedback = 'none';
  }

  public checkAuth(partType: string, partId: number) {
    if (this.authSrv.tokenIsCurrent()) {
      this.getFullPart(partType, partId);
    } else {
      this.authSrv.reauthenticate().subscribe(
        authenticated => {
          if (authenticated) {
            this.getFullPart(partType, partId);
          }
        }
      );
    }
  }

  public gotoSection(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  protected getFullPart(partType: string, partId: number) {
    this.workbookSrv.getSingleExamSubPart(partType, partId).subscribe(
      (res: ExamSubPart) => {
        this.fullPart = res;

        /** setup navigation for next/previous question */
        this.setNextAndPreviousNav(this.fullPart.id);

        this.scroller.scroll({
          document: this.document,
          scrollTarget: '#singleQuestionBox',
          duration: 700,
          easingLogic: this.quintEasing
        });
      }
    );
  }

  private setNextAndPreviousNav(currentId: number) {
    const foundIdx: any = this.listings.findIndex(ele => ele.id === currentId);

    if (-1 !== foundIdx) {
      /** calculate previous id */
      const prevCalc = (foundIdx - 1);
      if (prevCalc >= 0) {
        this.questionPrevious = this.listings[prevCalc]['id'];
      } else {
        this.questionPrevious = null;
      }

      /** calculate next id */
      const nextCalc = (foundIdx + 1);
      if (undefined !== this.listings[nextCalc]) {
        this.questionNext = this.listings[nextCalc]['id'];
      } else {
        this.questionNext = null;
      }
    }
  }

  protected quintEasing: EasingLogic = (t: number, b: number, c: number, d: number): number => {
    t /= d/2;
    if (t < 1) return c/2*t*t*t*t*t + b;
    t -= 2;
    return c/2*(t*t*t*t*t + 2) + b;
  }
}
