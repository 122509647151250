import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { DocumentService, VideoService } from '../_services';
import { SidebarPulloutComponent } from '../_shared/sidebar-pullout/sidebar-pullout.component';
import { renderAvWindow } from '../_helpers';
import { OnlineDocument } from '../_models';

@Component({
  selector: 'free-material',
  templateUrl: './free-material.component.pug',
  styleUrls: ['./free-material.component.scss']
})
export class FreeMaterialComponent implements OnInit, OnDestroy {
  public stepByStepDocument$: Observable<OnlineDocument>;
  public videoCourseSampler$: Observable<{videoId: number, title: string}>;

  @ViewChild('sideBar', {static: false}) pullOutList: SidebarPulloutComponent;

  constructor(private videoSrv: VideoService,
              private documentSrv: DocumentService) { }

  ngOnInit() {
    this.videoCourseSampler$ = this.videoSrv.getByTag('online-course-sampler');
    this.stepByStepDocument$ = this.documentSrv.getFullDocument('step-guide')
  }

  public viewPulloutListing(type: string) {
    this.pullOutList.show();
    this.pullOutList.pulloutType = type;
  }

  public initAvWindow(type: string, src: any, directory: string = null) {
    renderAvWindow(type, src, directory);
  }

  ngOnDestroy() {
    this.pullOutList.visibility = 'hide';
  }
}
